import { useGetValidacionesQuery } from "../../../services/docs";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../components/EjemploTabla";
import { Spinner } from "../../../components/Spinner";
import { TablaTramitesComponent } from "../../../components/TablaTramitesComponent";

export const ValidacionesScreen = () => {
  const { data, isLoading } = useGetValidacionesQuery();
  const navigate = useNavigate();
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`);
  };
  return (
    <div className="card">
      <div className="w-full text-center">
        <h1 className="text-1xl text-gray-600">Validar Documentos</h1>
        <hr className="my-2" />
      </div>
      <div className="space-y-6">
        {isLoading && (
          <div className="flex w-full justify-center">
            <Spinner />
          </div>
        )}
        {data && data.validaciones.length === 0 && (
          <div className="my-4 rounded bg-danger py-2 text-center text-white">
            No tienes pendientes
          </div>
        )}
        {data && data.validaciones.length > 0 && (
          <>
            <TablaTramitesComponent
              data={data.validaciones}
              texto_accion="Ver Mas"
              accion={verMas}
              ejecutivos={[]}
              cargando={false}
            />
          </>
        )}
      </div>
    </div>
  );
};
