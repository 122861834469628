import { Route, Routes } from "react-router-dom";
import { HomeScreen } from "../pages/private/200/HomeScreen";
import { ArchivosErroresScreen } from "../pages/emisiones/ArchivosErroresScreen";
import { ConsultarEmisionesScreen } from "../pages/emisiones/ConsultarEmisionesScreen";
import { ReportesEmisionesScreen } from "../pages/emisiones/ReportesEmisionesScreen";
import { GenerarEmisionesScreen } from "../pages/emisiones/GenerarEmisionesScreen";
import { VerEmisionesScreen } from "../pages/emisiones/VerEmisionesScreen";

export const EmsionesRoutes = () => {
  return (
    <Routes>
      <Route path="/inicio" element={<HomeScreen />}></Route>
      <Route path="/mis-pendientes" element={<ArchivosErroresScreen />}></Route>
      <Route
        path="/consulta-emisiones"
        element={<ConsultarEmisionesScreen />}
      ></Route>
      <Route
        path="/reportes-emisiones"
        element={<ReportesEmisionesScreen />}
      ></Route>
      <Route
        path="/generar-emisiones"
        element={<GenerarEmisionesScreen />}
      ></Route>
      <Route path="/ver-emisiones" element={<VerEmisionesScreen />}></Route>
      <Route path="/*" element={<HomeScreen />}></Route>
    </Routes>
  );
};
