import { useGetDocsListQuery } from "../../../services/docs"
import { useParams } from "react-router-dom"
import { Spinner } from "../../../components/Spinner"
import { ItemEstatusDocumento } from "../../../components/ItemEstatusDocumento"

export const ExpendienteScreen = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams()
  const { data, isLoading } = useGetDocsListQuery({ siniestro, tramite: tramite_id })
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return (
    <>
      <div className="mx-auto space-y-4 justify-start items-center mb-5">
        <div className="border-solid border-gray-ultra-light flex gap-1 flex-row border-b-2 w-full relative">

          <div className="rounded-t border-solid px-2 flex flex-row gap-4 items-center justify-start h-12 border-b-4 relative overflow-hidden w-full cursor-pointer bg-light border-gray-ultra-light">
            <ItemEstatusDocumento
              color={'bg-gray'}
              label={'Documento No Cargado'}
            />
            <ItemEstatusDocumento
              color={'bg-green-400'}
              label={'Documento Cargado'}
            />
            <ItemEstatusDocumento
              color={'bg-primary'}
              label={'Digital Verificado'}
            />
            <ItemEstatusDocumento
              color={'bg-yellow-400'}
              label={'Digital Rechazado'}
            />
          </div>
        </div>
      </div>
      <div className='flex w-full'>
        <p className="pt-5 text-2xl @lg:text-2xl @xl:text-2xl">
          Documentos del Titular
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4 place-items-stretch">
        {data && data.documentos && data.documentos.length > 0 && data.documentos.map((doc: any) => {
          let urlDoc = '';
          let color = '';
          let fi_cargado = 0;
          let fd_cargado = '';
          if (doc.documento) {
            if (doc.documento.length > 0) {
              urlDoc = doc.documento[0].fc_ruta_documento;
              fi_cargado = doc.documento[0].fi_cargado_sftp;
              fd_cargado = doc.documento[0].fd_carga_sftp;
              switch (doc.documento[0].fi_rechazo_digital) {
                case 0:
                  color = 'bg-green-400'
                  break
                case 1:
                  color = 'bg-primary'
                  break
                case 2:
                  color = 'bg-yellow-400'
                  break
                default:
                  color = 'bg-gray'
                  break
              }
            }
          }

          return (
            <div className="card" key={doc.id}>
              <div className={`w-12 ${color} h-2 rounded-xl overflow-hidden`}></div>
              <div className="flex">
                <div className="flex-item">
                  <p className='font-bold'>{doc.fi_documento}. {doc.fc_nombre_documento}</p>
                  <p>{doc.fc_descripcion_documento}</p>
                </div>
              </div>
              {urlDoc &&
                <div className="flex-item mt-5">
                  <a className='p-3 text-white text-right text-sm bg-primary-light rounded' href={urlDoc} target='_blank' >Ver Documento</a>
                </div>
              }
              {fi_cargado === 1 &&
                <div className="flex-item mt-5">
                  <p>Documento cargado</p>
                  <p> {fd_cargado}</p>
                </div>
              }
            </div>
          )
        })}
      </div>
      <div className='flex w-full'>
        <p className="pt-5 text-2xl @lg:text-2xl @xl:text-2xl">
         Documentos Adicionales
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4 place-items-stretch">
        {data && data.adicionales && data.adicionales.length > 0 && data.adicionales.map((doc: any) => {
          let urlDoc = '';
          let fi_cargado = 0;
          let fd_cargado = '';
          let color = '';
          if (doc.documento) {
            if (doc.documento.length > 0) {
              fi_cargado = doc.documento[0].fi_cargado_sftp;
              fd_cargado = doc.documento[0].fd_carga_sftp;
              urlDoc = doc.documento[0].fc_ruta_documento;
              switch (doc.documento[0].fi_rechazo_digital) {
                case 0:
                  color = 'bg-green-400'
                  break
                case 1:
                  color = 'bg-primary'
                  break
                case 2:
                  color = 'bg-yellow-400'
                  break
                default:
                  color = 'bg-gray'
                  break
              }
            }
          }

          return (
            <div className="card" key={doc.id}>
              <div className={`w-12 ${color} h-2 rounded-xl overflow-hidden`}></div>
              <div className="flex">
                <div className="flex-item">
                  <p className='font-bold'>{doc.fc_nombre_documento}</p>
                  <p>{doc.fc_descripcion_documento}</p>
                </div>
              </div>
              {urlDoc &&
                <div className="flex-item mt-5">
                  <a className='p-3 text-white text-right text-sm bg-primary-light rounded' href={urlDoc} target='_blank' >Ver Documento</a>
                </div>
              }
              {fi_cargado === 1 &&
                <div className="flex-item mt-5">
                  <p>Documento cargado</p>
                  <p> {fd_cargado}</p>
                </div>
              }
            </div>
          )
        })}
      </div>
    </>
  )

}
