import { Spinner } from "../../../components/Spinner";
import {
  useAgregarEspecialistaMutation,
  useBuscarEspecialistaMutation,
  useGetEspecialistasQuery,
  useLazyGetEspecialistasQuery,
  useRmEspecialistaMutation,
} from "../../../services/docs";
import { useForm } from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const AgregarEspecialistaScreen = () => {
  const navigate = useNavigate();
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fc_busqueda: "",
    fc_valor: "",
  });
  const { data: getEspecialista, isLoading: EisLoading } =
    useGetEspecialistasQuery();
  const [getEspecialistaLazy] = useLazyGetEspecialistasQuery();
  const [buscarTramiteAction, { data: especialista, isLoading, error }] =
    useBuscarEspecialistaMutation();
  const [rmEspecialistaAction, { isLoading: isLCambio }] =
    useRmEspecialistaMutation();
  const [
    agregarEspecialistaAction,
    { isLoading: isLoadingAgregar, error: errorAgregar },
  ] = useAgregarEspecialistaMutation();

  const buscarTramite = async () => {
    if (formulario.fc_busqueda.trim() === "") {
      toastr.error("Debe seleccionar un tipo de búsqueda");
      return;
    }
    if (formulario.fc_valor === "") {
      toastr.error("Debes de agregar un valor de busqueda");
      return;
    }
    await buscarTramiteAction({
      ...formulario,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 404) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
  };
  const CambiarDescanso = async (e) => {
    if (!e) {
      toastr.error("Debe seleccionar un Especialista");
      return;
    }
    let descanso = +e.fi_descanso === 1 ? 0 : 1;
    const resp = await rmEspecialistaAction({
      fi_usuario_id: e.fi_usuario_id,
      fi_descanso: descanso,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 404) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (resp.message) {
      getEspecialistaLazy();
      toastr.info(resp.message);
    }
  };
  const rmEspecialista = async (e) => {
    if (!e) {
      toastr.error("Debe seleccionar un Especialista");
      return;
    }
    const resp = await rmEspecialistaAction({
      fi_usuario_id: e.fi_usuario_id,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 404) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (resp.message) {
      getEspecialistaLazy();
      toastr.info(resp.message);
    }
  };
  const agregarEspecialista = async (e) => {
    if (!e) {
      toastr.error("Debe seleccionar un Ejecutivo");
      return;
    }
    let r = await agregarEspecialistaAction({
      fi_usuario_id: e.id,
      fc_username: e.username,
      fc_nombre: e.nombre,
      fc_apellido_paterno: e.apellido_paterno,
      fc_apellido_materno: e.apellido_materno,
      fc_email: e.email,
      fc_telefono: e.telefono,
      fc_profile: e.profile,
      fc_area: e.area,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 404) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (r.message) {
      getEspecialistaLazy();
      toastr.info(r.message);
    }
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <h1 className="text-2xl">Buscar Especialista</h1>
          <hr className="my-2" />
        </div>
        <div className="grid grid-cols-3 place-items-stretch gap-4">
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="fc_busqueda" className="my-2">
                Tipo Búsqueda:
              </Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fc_busqueda"
                onChange={handleSelectChange}
              >
                <option value="">Selecciona</option>
                <option value="id">ID</option>
                <option value="email">Correo electronico</option>
                <option value="username">Usuario</option>
              </select>
            </div>
          </div>
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label className="my-2" htmlFor="fc_valor">
                Valor a buscar:
              </Label>
              <Input type="text" name="fc_valor" onChange={handleInputChange} />
            </div>
          </div>
          <div>
            <div className="mt-6 flex flex-col px-6 py-2">
              {!isLoading && (
                <Button onClick={buscarTramite}>Buscar Especialista</Button>
              )}
              {isLoading && <Spinner />}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 grid grid-cols-3 place-items-stretch gap-4">
        {especialista &&
          especialista.ejecutivos.length > 0 &&
          especialista.ejecutivos.map((e: any) => {
            return (
              <div className="card flex" key={e.id}>
                <div className="w-2/3">
                  <h6 className="w-full text-gray">Usuario:</h6>
                  <p className="w-full">{e.username}</p>
                  <hr />
                  <h6 className="w-full text-gray">Nombre:</h6>
                  <p className="w-full">
                    {e.nombre} {e.apellido_paterno} {e.apellido_materno}
                  </p>
                  <h6 className="w-full text-gray">Correo:</h6>
                  <p className="w-full">{e.email}</p>
                  <hr />
                  <h6 className="w-full text-gray">Area:</h6>
                  <p className="w-full">{e.area}</p>
                  <h6 className="w-full text-gray">Perfil:</h6>
                  <p className="w-full">{e.profile}</p>
                </div>
                <div className="w-1/3">
                  {isLoadingAgregar && <Spinner />}
                  {!isLoadingAgregar && (
                    <Button onClick={() => agregarEspecialista(e)}>
                      Agregar Especialista
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div className="mt-10 grid grid-cols-3 place-items-stretch gap-4">
        {getEspecialista &&
          getEspecialista.especialistas.length > 0 &&
          getEspecialista.especialistas.map((e: any) => {
            return (
              <div className="card" key={e.id}>
                <div className="card__content text-secondary-color text-sm">
                  {e.fc_username.toUpperCase()} / {e.fc_profile.toUpperCase()}
                </div>
                <div className="card__title p-0 text-lg font-bold">
                  {e.fc_apellido_materno.toUpperCase()}{" "}
                  {e.fc_apellido_paterno.toUpperCase()}{" "}
                  {e.fc_nombre.toUpperCase()}
                </div>
                <div className="card__date text-xs text-gray-600">
                  {e.fc_email}
                </div>
                <div className="card__date mt-2 text-xs text-gray-600">
                  {isLCambio && <Spinner />}
                  {!isLCambio && 
                  <Button
                    className="bg-primary"
                    onClick={() => CambiarDescanso(e)}
                  >
                    {e.fi_descanso === 1
                      ? "Quitar descanso"
                      : "Poner en descanso"}
                  </Button>
                  }
                </div>
                <div className="card__date mt-2 text-xs text-gray-600">
                  {isLCambio && <Spinner />}
                  {!isLCambio && (
                    <Button
                      className="bg-danger"
                      onClick={() => rmEspecialista(e)}
                    >
                      Eliminar Especialista
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
