import { Spinner } from "../../components/Spinner";
import Button from "../../components/ui/Button";
import {
  useLazyEnviarFullQuery,
  useLazyGenerarEmisionQuery,
  useVerArchivosEmisionQuery,
} from "../../services/config.service";
import { useLazyGetPendientesFullQuery } from "../../services/docs";
import { useState } from "react";
import { toastr } from "react-redux-toastr";

export const GenerarEmisionesScreen = () => {
  const { data: archivos, isLoading: loadingArchivos } =
    useVerArchivosEmisionQuery();
  const [generarAction, { data, isLoading: loadingGenerar }] =
    useLazyGenerarEmisionQuery();
  const [pendientesFullAction, { data: dataFull, isLoading: loadingFull }] =
    useLazyGetPendientesFullQuery();
  const [
    enviarFullAction,
    { data: dataEnvioFull, isLoading: loadingEnvioFull },
  ] = useLazyEnviarFullQuery();
  const [cargando, setCargando] = useState(false);

  const generar = async () => {
    setCargando(true);
    const g = await generarAction().unwrap();
    if (g) {
      setCargando(false);
      toastr.info("Archivos procesados");
    }
  };
  const pendientesFull = async () => {
    pendientesFullAction();
  };
  const enviarFull = async () => {
    enviarFullAction();
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <h1 className="text-2xl">Generar Emisión</h1>
          <hr className="my-2" />
        </div>
        <div className="mt-10 w-full ">
          <h1 className="mb-3 bg-primary pr-3 text-right text-white">
            Archivos por Emitir
          </h1>
          {loadingArchivos && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          )}
          <div className="grid grid-cols-3 place-items-stretch gap-4 border p-2">
            {!loadingArchivos && archivos && archivos.lista.length === 0 && (
              <div className="flex w-full justify-center">
                <div className="my-4 rounded bg-primary-light p-2 text-center text-white">
                  No tienes archivos por procesar
                </div>
              </div>
            )}
            {!loadingArchivos &&
              archivos &&
              archivos.lista.length > 0 &&
              archivos.lista.map((a: any, i: number) => {
                return (
                  <div key={a.name} className="flex">
                    <div className="text-xs">
                      {i + 1}.-{a.name}{" "}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="grid grid-cols-3 place-items-stretch gap-4 p-5">
            <div className="flex">
              <Button onClick={generar}>Generar Emisión</Button>
            </div>
            {(loadingGenerar || cargando) && (
              <div className="flex">
                <div className="flex w-full justify-center">
                  <Spinner />
                </div>
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 place-items-stretch gap-4 p-5">
            <div className="flex">
              <Button className="m-3" onClick={enviarFull}>
                Enviar Fullfilment
              </Button>
            </div>
            <div className="flex">
              <Button className="m-3" onClick={pendientesFull}>
                Faltantes
              </Button>
              {!loadingFull && dataFull && (
                <div className="m-3 text-2xl">{dataFull.pendientes}</div>
              )}
            </div>
            {loadingEnvioFull && (
              <div className="flex">
                <div className="m-3 flex w-full justify-center">
                  <Spinner />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
