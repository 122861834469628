import { useParams } from "react-router-dom";
import { useGetNotificacionesQuery } from "../services/docs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/Acordion";
import { Spinner } from "@phosphor-icons/react";
import { DataTable } from "./EjemploTabla";

const columns: any[] = [
  {
    accessorKey: 'fi_etapa',
    header: 'Etapa',
  },
  {
    accessorKey: 'fi_movimiento',
    header: 'Movimiento',
  },
  {
    accessorKey: 'fc_notificacion',
    header: 'Notificación',
  },
  {
    accessorKey: 'fc_mensaje_notificacion',
    header: 'Mensaje',
  },
  {
    accessorKey: 'fd_fecha_envio',
    header: 'Fecha Envio',
  },
]
export const AcordionNotificacionesComponent = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams()
  const { data, isLoading } = useGetNotificacionesQuery({ siniestro, tramite: tramite_id })
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      defaultValue="activos"
    >
      <AccordionItem value="activos">
        <div className="card">
          <AccordionTrigger>
            <p className="text-1xl">Notificaciones APP</p>
          </AccordionTrigger>
          <AccordionContent>
            <div className="space-y-4">
              <div className="group space-y-4">
                <DataTable data={data.notificacionesApp} columns={columns} action={()=>{}} />
              </div>
            </div>
          </AccordionContent>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

