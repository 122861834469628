import { useGetPendientesQuery } from "../../services/docs"
import { useNavigate } from "react-router-dom"
import { DataTable } from "../../components/EjemploTabla"
import { Spinner } from "../../components/Spinner"

export const ArchivosErroresScreen = () => {
  const { data,isLoading } = useGetPendientesQuery()
  const navigate = useNavigate()
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`)
  }
  const columns: any[] = [
    {
      accessorKey: 's_fc_descripcion_producto',
      header: 'Producto',
    },
    {
      accessorKey: 's_fc_descripcion_cobertura',
      header: 'Cobertura',
    },
    {
      accessorKey: 't_fc_nombre_asegurado',
      header: 'Asegurado',
    },
    {
      accessorKey: 'fc_nombre_etapa',
      header: 'Etapa',
    },
    {
      accessorKey: 't_fc_poliza',
      header: 'Póliza',
    },
    {
      accessorKey: 't_fc_siniestro',
      header: 'Siniestro',
    },
    {
      accessorKey: 't_id',
      header: 'Tramite',
    },
    {
      accessorKey: 'action',
      header: 'Action',
    },
  ]
  return (
    <div className="card">
        <div className="w-full">
          <h1 className='text-2xl'>Archivos Con Errores</h1>
          <hr className='my-2' />
        </div>
      <div className="space-y-6">
        {isLoading &&
          <div className='flex w-full justify-center'><Spinner /></div>
        }
        {data && data.pendientes.length === 0 && (
          <div className="bg-danger my-4 py-2 text-center text-white rounded">No tienes Archivos Pendientes</div>
        )}
        {data && data.pendientes.length > 0 && (
          <>
            <DataTable data={data.pendientes} columns={columns} action={verMas} />
          </>
        )}
      </div>
    </div>
  )
}
