"use client";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { elapsed, elapsedMinutos, fecha_actual } from "../hooks/elapsed";
import Button from "./ui/Button";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  action;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  action,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => {
                  const p: any = cell.row.original;
                  console.log(p);
                  return (
                    <TableCell key={cell.id}>
                      {cell.column.columnDef.header !== "Action" &&
                        cell.column.columnDef.header !== "Tiempo" &&
                        cell.column.columnDef.header !== "Tiempo T" &&
                        cell.column.columnDef.header !== "Fecha Envio" &&
                        cell.column.columnDef.header !== "Fecha Inicial" &&
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      {cell.column.columnDef.header === "Action" && (
                        <Button onClick={() => action(p)}>
                          {p.action ? p.action : "Ver Mas"}
                        </Button>
                      )}
                      {cell.column.columnDef.header === "PDF" && (
                        <a
                          className="rounded-2xl bg-primary p-1 text-white"
                          href={p.p_fc_url_poliza}
                          target="_blank"
                        >
                          PDF
                        </a>
                      )}
                      {cell.column.columnDef.header === "Tiempo" &&
                        elapsedMinutos(p.ttes_fi_minutos_transcurridos)}

                      {cell.column.columnDef.header === "Fecha Envio" &&
                        fecha_actual(p.fd_fecha_envio)}

                      {cell.column.columnDef.header === "Fecha Inicial" &&
                        fecha_actual(p.ttes_fd_fecha_inicial)}

                      {cell.column.columnDef.header === "Tiempo T" &&
                        elapsed(p.sem_fd_fecha_inicial)}
                      {cell.column.columnDef.header === "VALIDAR" && (
                        <Button onClick={() => action(p)}>
                          {p.action ? p.action : "Validar"}
                        </Button>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
