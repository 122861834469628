import { motion } from "framer-motion";

const Loader = () => {
  return (
    <motion.div className="flex h-4 w-20 items-center justify-center gap-2">
      {[...Array(3)].map((_, index) => (
        <motion.div
          key={index}
          className="h-4 w-4 rounded bg-primary-light"
          animate={{
            width: [16, 4, 16],
            height: [16, 4, 16],
            opacity: [0.25, 1, 0.25],
            backgroundColor: [
              "rgb(0 186 186)",
              "rgb(255 255 255)",
              "rgb(0 186 186)",
            ],
          }}
          transition={{
            repeat: Infinity,
            delay: index * 0.125,
            // yoyo: Infinity,
            duration: 1,
          }}
        />
      ))}
    </motion.div>
  );
};

export default Loader;
