import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../store";

export const RequiereAuth = (p) => {
  const { usuario, token } = useSelector((state: RootState) => state.ui)
  if (!token) {
    return <Navigate to="/auth/login" replace />
  }
  console.log('p',usuario.profile)
  if (p.perfil) {
    if (p.perfil !== usuario.profile) {
      return <Navigate to="/auth/login" replace />
    }
  }
  return p.children
}
