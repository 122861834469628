import { Spinner } from "../../../components/Spinner";
import {
  useBuscarPolizaMutation,
  useDatosTramiteMutation,
  useNuevoTramiteMutation,
} from "../../../services/docs";
import { useForm } from "../../../hooks/useForm";
import { ItemDato } from "../../../components/ItemDato";
import { Fragment, useState } from "react";
import { ItemPolizaComponent } from "../../../components/ItemPolizaComponent";
import { useNavigate } from "react-router-dom";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const NuevoTramite = () => {
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fc_tipo_busqueda: "",
    fc_valor: "",
  });
  const {
    formulario: uno,
    handleInputChange: hcUno,
    handleChangeAll: hca,
  } = useForm({
    fi_cobertura_id: 0,
    fi_numero_asegurado: 0,
    fc_clave_asegurado: "",
    poliza: "",
    fecha_siniestro: "",
    fc_poliza_aseguradora: "",
  });
  const {
    formulario: dos,
    handleInputChange: hcDos,
    handleChangeAll: hcaDos,
  } = useForm({
    fi_cobertura_id: 0,
    fi_numero_asegurado: 0,
    fc_clave_asegurado: "",
    fecha_siniestro: "",
    siniestro: "",
    montoReclamar: "",
    nombreContacto: "",
    telefonoContacto: "",
    correoContacto: "",
    descripcion: "",
  });
  const [buscarPolizaAction, { data: poliza, isLoading, error }] =
    useBuscarPolizaMutation();
  const [nuevoTramite, { isLoading: isLoadingTramite }] =
    useNuevoTramiteMutation();
  const [datosTramite, { isLoading: isLoadingDatos }] =
    useDatosTramiteMutation();
  const [seleccion, setSeleccion] = useState(null);
  const [seleccionSiniestro, setSeleccionSiniestro] = useState(null);
  const navigate = useNavigate();
  const seleccionarTramite = (p: any, a: any, c: any) => {
    console.log("c", c);
    let formUno = {
      fi_cobertura_id: c.fi_numero_cobertura,
      fi_numero_asegurado: c.fi_numero_asegurado,
      fc_clave_asegurado: c.fc_clave_asegurado,
      poliza: c.fc_numero_poliza,
      fecha_siniestro: "",
      fc_poliza_aseguradora: p.poliza[0].fc_valor_item,
    };
    hca({ ...formUno });
    setSeleccion({ p: p, a: a, c: c });
  };
  const nuevoTramiteAction = async () => {
    const tramite = await nuevoTramite({
      ...uno,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });

    if (tramite) {
      let formDos = {
        fi_cobertura_id: uno.fi_cobertura_id,
        fi_numero_asegurado: uno.fi_numero_asegurado,
        fc_clave_asegurado: uno.fc_clave_asegurado,
        fecha_siniestro: uno.fecha_siniestro,
        siniestro: tramite.siniestro,
        montoReclamar: "",
        nombreContacto: "",
        telefonoContacto: "",
        correoContacto: "",
        descripcion: "",
      };
      hcaDos({ ...formDos });
      setSeleccionSiniestro(tramite.siniestro);
    }
  };
  const datosAction = async () => {
    const tramite = await datosTramite({
      ...dos,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });

    if (tramite) {
      toastr.info(tramite.siniestro);
    }
  };
  const verMas = (p) => {
    if (p.id) {
      navigate(`/home/nuevo-tramite/${p.id}`);
    }
  };

  const buscarTramite = async () => {
    if (formulario.fc_tipo_busqueda === "") {
      toastr.error("Debe seleccionar un tipo de búsqueda");
      return;
    }
    if (formulario.fc_valor === "") {
      toastr.error("Debes de agregar un valor de busqueda");
      return;
    }
    const dat = await buscarPolizaAction({
      ...formulario,
      fc_tipo_busqueda: formulario.fc_tipo_busqueda,
    })
      .unwrap()
      .catch((err) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    console.log(dat);
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <h1 className="text-2xl">Nuevo tramite</h1>
        </div>
        <div className="mb-3 mt-5 w-full">
          <h6>Buscar Póliza</h6>
          <hr />
        </div>
        <div className="ms:grid-cols-1 grid place-items-stretch gap-4 md:grid-cols-3">
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="" className="my-2">
                Tipo de Búsqueda
              </Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fc_tipo_busqueda"
                onChange={handleSelectChange}
              >
                <option value="">Selecciona</option>
                <option value="poliza">Póliza</option>
                <option value="nombre">Nombre Asegurado</option>
                <option value="rfc">RFC</option>
              </select>
            </div>
          </div>
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="" className="my-2">
                Valor a buscar:
              </Label>
              <Input type="text" name="fc_valor" onChange={handleInputChange} />
            </div>
          </div>
          <div>
            <div className="mt-6 flex flex-col px-6 py-2">
              <Button onClick={buscarTramite}>Buscar</Button>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="flex w-full justify-center">
            <Spinner />
          </div>
        )}
        <div className="mt-10 grid grid-cols-1 place-items-stretch gap-4">
          {poliza && poliza.polizas && poliza.polizas.length === 0 && (
            <div className="my-4 rounded bg-danger py-2 text-center text-white">
              No se encontraron datos
            </div>
          )}
        </div>
      </div>
      {poliza && poliza.polizas && poliza.polizas.length > 0 && (
        <div className="mt-10 grid place-items-stretch gap-4 sm:grid-cols-1 md:grid-cols-2">
          {poliza.polizas.map((p: any) => {
            return (
              <div
                key={p.id + "-" + p.fi_tipo_asegurado}
                className="card rounded"
              >
                <div className="px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    {p.fi_numero_producto} - {p.fc_descripcion_producto}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    {p.fc_descipcion_aseguradora}
                  </p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Póliza Interna:
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {p.fc_numero_poliza}
                        <span className="ml-2 inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                          {p.fc_estatus_poliza}
                        </span>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Póliza Aseguradora:
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {p.fc_poliza_aseguradora}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="mt-2 text-sm font-medium leading-6 text-gray-900">
                        Nombre:
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="w-full">
                          <span className="inline-flex items-center rounded-md bg-primary-ultra-light px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {p.fi_tipo_asegurado === 1
                              ? "Titular"
                              : "Contratante"}
                          </span>
                        </div>
                        {p.fc_nombre_asegurado}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        RFC:
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {p.fc_rfc}
                      </dd>
                    </div>
                  </dl>
                  <div className="w-full text-right">
                    <button
                      onClick={() => verMas(p)}
                      className="m-1 rounded border border-gray-500/10 bg-primary-ultra-light px-4 py-2 text-sm text-black"
                    >
                      Ver mas
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
