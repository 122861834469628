import { useNavigate, useParams } from "react-router-dom";
import {
  useFinalizarValidacionMutation,
  useGetDocsListQuery,
} from "../../../services/docs";
import { ItemEstatusDocumento } from "../../../components/ItemEstatusDocumento";
import { ItemDocumentoValidar } from "../../../components/ItemDocumentoValidar";
import { DocumentacionAdicionalComponent } from "../../../components/DocumentacionAdicionalComponent";
import { Spinner } from "../../../components/Spinner";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const PrevalidacionScreen = () => {
  let { siniestro, tramite_id } = useParams();
  const { data, isLoading } = useGetDocsListQuery({
    siniestro,
    tramite: tramite_id,
  });
  const [
    finalizarValidacion,
    { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset },
  ] = useFinalizarValidacionMutation();
  const navigate = useNavigate();

  const valor: any = [];
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  const finalizarValidacionAction = async () => {
    const resp = await finalizarValidacion({
      siniestro,
      tramite_id: +tramite_id,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        } else {
          toastr.error(err.data.message);
        }
      });
    if (resp) {
      toastr.error(resp.message);
      navigate("/view/siniestro/" + siniestro + "/" + tramite_id + "/proceso");
    }
  };
  return (
    <div className="mx-auto mb-5 items-center justify-start space-y-4">
      <div className="relative flex w-full flex-row gap-1 border-b-2 border-solid border-gray-ultra-light">
        <div className="relative flex h-12 w-full cursor-pointer flex-row items-center justify-start gap-4 overflow-hidden rounded-t border-b-4 border-solid border-gray-ultra-light bg-light px-2">
          <ItemEstatusDocumento
            color={"bg-gray"}
            label={"Documento No Cargado"}
          />
          <ItemEstatusDocumento
            color={"bg-green-400"}
            label={"Documento Cargado"}
          />
          <ItemEstatusDocumento
            color={"bg-primary"}
            label={"Digital Verificado"}
          />
          <ItemEstatusDocumento
            color={"bg-yellow-400"}
            label={"Digital Rechazado"}
          />
        </div>
      </div>
      {!isLoadingCarga && data && data.cop && (
        <div className="flex w-full justify-center">
          <Button onClick={finalizarValidacionAction}>
            Finalizar Validación
          </Button>
        </div>
      )}
      {isLoadingCarga && (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      )}
      <h1>Documentos Titular</h1>
      <div className="grid grid-cols-3 place-items-stretch gap-4">
        {data &&
          data.documentos &&
          data.documentos.map((doc: any, i: number) => {
            valor.push({ comentario: "", respuesta: 0, calificado: 0 });
            return (
              <ItemDocumentoValidar
                key={doc.id}
                valor={valor}
                doc={doc}
                i={i}
                siniestro={siniestro}
                tramite_id={tramite_id}
              />
            );
          })}
      </div>
      <h1>Adicional</h1>
      <div className="grid grid-cols-3 place-items-stretch gap-4">
        {data &&
          data.adicionales &&
          data.adicionales.map((doc: any, i: number) => {
            valor.push({ comentario: "", respuesta: 0, calificado: 0 });
            return (
              <ItemDocumentoValidar
                key={doc.id}
                valor={valor}
                doc={doc}
                i={i}
                siniestro={siniestro}
                tramite_id={tramite_id}
              />
            );
          })}
      </div>
      <div className="my-5">
        <DocumentacionAdicionalComponent />
      </div>
    </div>
  );
};
