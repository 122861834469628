import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./App";
import { store } from "./store";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ReduxToastr from "react-redux-toastr";

const root = ReactDOM.createRoot(document.getElementById("root")!);

if (process.env.NODE_ENV === "development") {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfPjkMnAAAAAKW5h-7VKqIl61_4bKBhdupUHvXm"
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          <App />
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfPjkMnAAAAAKW5h-7VKqIl61_4bKBhdupUHvXm"
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          <App />
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </Provider>
  );
}
