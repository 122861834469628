import {
  useCargaDocumentoMutation,
  useEditarDocumentoMutation,
  useLazyGetDocsListQuery,
} from "../services/docs";
import { Spinner } from "./Spinner";
import Button from "./ui/Button";
import { toastr } from "react-redux-toastr";

export const ItemDocumentoCarga = ({
  doc,
  i,
  valor,
  siniestro,
  tramite_id,
}) => {
  const [
    cargaSend,
    { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset },
  ] = useCargaDocumentoMutation();
  const [tigger] = useLazyGetDocsListQuery();
  const [eliminar, { isLoading: eliminarLoading }] =
    useEditarDocumentoMutation();
  const eliminarDocumento = async () => {
    const resp = await eliminar({
      fc_siniestro: siniestro,
      fi_tramite_id: +tramite_id,
      fi_documento_id: doc.id,
      fi_documento_activo: 0,
    }).unwrap();
    if (resp) {
      toastr.info(resp.message);
      tigger({ siniestro, tramite: tramite_id });
    }
  };
  const onChange = (e: any, doc: any, i: number) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const onLoad = (fileString: any) => {
    valor[i].imagen = fileString;
    console.log(fileString);
    doc.cargado = true;
  };
  const cargarDocumento = async () => {
    if (!valor[i].imagen) {
      toastr.error("Debes de cargar un documento");
      return;
    }
    const resp = await cargaSend({
      documento: valor[i].imagen,
      documento_id: doc.id,
      siniestro: siniestro,
      tramite_id: Number(tramite_id),
      adicional: doc.adicional,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400 || err.status === 403) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (resp) {
      toastr.info(resp.message);
      tigger({ siniestro, tramite: tramite_id });
    }
  };
  let color = "bg-gray";
  let urlDoc = "";

  if (doc.documento) {
    if (doc.documento.length > 0) {
      urlDoc = doc.documento[0].fc_ruta_documento;
      switch (doc.documento[0].fi_rechazo_digital) {
        case 0:
          color = "bg-green-400";
          doc.cargado = true;
          break;
        default:
          color = "bg-gray";
          break;
        case 1:
          color = "bg-primary";
          break;
        case 2:
          color = "bg-yellow-400";
          break;
      }
    }
  }
  return (
    <div
      key={doc.id}
      className="w-full flex-row items-center rounded-xl border bg-white p-2 shadow"
    >
      <div className={`w-1/4 ${color} h-2 overflow-hidden rounded-xl`}></div>
      <div className="relative flex-row items-center space-x-1">
        {isLoadingCarga && <Spinner />}
        {((!isLoadingCarga &&
          doc.documento &&
          doc.documento.length > 0 &&
          doc.documento[0].fi_rechazo_digital !== 1) ||
          (!isLoadingCarga && !doc.documento) ||
          (!isLoadingCarga && doc.documento.length === 0)) && (
          <div className="mt-2 flex w-full justify-center">
            {/* <input onChange={(e) => onChange(e, doc, i)} className="my-4 w-1/2 mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="small_size" type="file" /> */}
            <div className="flex w-full items-center justify-center">
              <label
                htmlFor={"small_size_" + i}
                className={
                  doc.cargado
                    ? "flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-primary-light bg-primary-light  dark:border-primary-light dark:bg-primary-light"
                    : "group flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-primary-light bg-primary hover:bg-primary-light dark:border-primary-light dark:bg-primary-ultra-light dark:hover:border-primary-light dark:hover:bg-primary-light"
                }
              >
                <div className="flex flex-col items-center justify-center pb-3 pt-3">
                  <svg
                    className={
                      doc.cargado
                        ? "mb-4 h-8 w-8 text-white"
                        : "mb-4 h-8 w-8 text-gray-500 group-hover:text-white dark:text-gray-400"
                    }
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p
                    className={
                      doc.cargado
                        ? "mb-2 text-sm text-white group-hover:text-white dark:text-white"
                        : "mb-2 text-sm text-gray-500 group-hover:text-white dark:text-gray-400"
                    }
                  >
                    <span className="font-semibold">Click</span> para cargar{" "}
                    {doc.fc_nombre_documento}
                  </p>
                </div>
                <input
                  id={"small_size_" + i}
                  onChange={(e) => onChange(e, doc, i)}
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
          </div>
        )}
        {!isLoadingCarga &&
          doc.documento &&
          doc.documento.length > 0 &&
          doc.documento[0].fi_rechazo_digital === 2 && (
            <div className="flex w-full justify-center">
              <p>{doc.documento[0].fc_comentarios_rechazo}</p>
            </div>
          )}
      </div>
      <div className="flex-grow p-3">
        <div className="font-semibold text-gray-700">
          {doc.fi_documento}. {doc.fc_nombre_documento}
        </div>
        <div className="text-sm text-gray-500">
          {doc.fc_descripcion_documento}
        </div>

        {((!isLoadingCarga &&
          doc.documento &&
          doc.documento.length > 0 &&
          doc.documento[0].fi_rechazo_digital !== 1) ||
          (!isLoadingCarga && !doc.documento) ||
          (!isLoadingCarga && doc.documento.length === 0)) && (
          <>
            <Button className="my-5" onClick={() => cargarDocumento()}>
              Cargar Documento
            </Button>
            {!eliminarLoading && (
              <Button
                className="my-5 bg-danger"
                onClick={() => eliminarDocumento()}
              >
                Eliminar Documento
              </Button>
            )}
            {eliminarLoading && <Spinner />}
          </>
        )}
        {urlDoc && (
          <a
            className="mt-3 rounded bg-gray-500 p-1 text-sm text-white"
            href={urlDoc}
            target="_blank"
          >
            Ver Documento
          </a>
        )}
      </div>
    </div>
  );
};
