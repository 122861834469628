import Button from "./ui/Button";
import {
  elapsed,
  elapsedCerrado,
  elapsedMinutos,
  fecha_actual,
} from "../hooks/elapsed";
import { Fragment, useState } from "react";
import { Spinner } from "./Spinner";

export interface TiemposProps {
  data: any;
}

export const TiemposComponent = ({ data }: TiemposProps) => {
  return (
    <div className="block w-full overflow-x-auto">
      <div className="grid grid-cols-1 gap-x-0 gap-y-6 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-0">
        <div className="card col-span-2">
          <div className="card-body">
            <h4 className="mb-5 text-lg font-semibold text-gray-500">
              Tracking Siniestros
            </h4>
            <ul className="timeline-widget relative">
              {data.semaforo &&
                data.semaforo.length > 0 &&
                data.semaforo.map((s: any, i: any, a: any) => {
                  console.log(s);
                  console.log(i);
                  console.log(data.semaforo.length - 1);
                  let tiempo_old: any;
                  let minutos = "0 min";
                  if (i !== data.semaforo.length - 1) {
                    tiempo_old = a[i + 1].ttes_fd_fecha_inicial;
                    minutos = elapsedCerrado(
                      s.ttes_fd_fecha_inicial,
                      tiempo_old
                    );
                  }

                  if (
                    i === data.semaforo.length - 1 &&
                    s.tce_fi_finalizado === 0
                  ) {
                    minutos = elapsed(s.ttes_fd_fecha_inicial);
                  }
                  return (
                    <li
                      key={s.ttes_id}
                      className="timeline-item relative mb-5 flex min-h-[70px] overflow-hidden"
                    >
                      <div className="timeline-time min-w-[95px] py-[6px] pr-5 text-end text-sm text-gray-500">
                        {fecha_actual(s.ttes_fd_fecha_inicial)}
                        <p>{s.tce_fc_grupo}</p>
                      </div>
                      <div className="timeline-badge-wrap flex flex-col items-center">
                        <div className="timeline-badge my-[10px] h-3 w-3 shrink-0 rounded-full border-2 border-primary bg-transparent"></div>
                        <div className="timeline-badge-border block h-full w-[1px] bg-primary"></div>
                      </div>
                      <div className="timeline-desc px-4 py-[6px]">
                        <p className="text-sm font-normal text-primary">
                          {s.tce_fc_nombre_etapa}
                        </p>
                        <p className="text-sm font-normal text-gray-500">
                          {s.fc_sub_etapa}
                        </p>
                        <p className="text-sm font-normal text-gray-500">
                          {s.eje_fc_nombre} {s.eje_fc_apellido_paterno}{" "}
                          {s.eje_fc_apellido_materno}
                        </p>
                        <p className="text-sm font-normal text-gray-500">
                          {minutos}
                        </p>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="">
          <div className="card h-full">
            <div className="card-body">
              <div className="grid grid-cols-2 place-items-stretch gap-5  text-center">
                {data &&
                  data.global &&
                  data.global.length > 0 &&
                  data.global.map((g: any) => {
                    return (
                      <Fragment key={g.grupo}>
                        <div className="card">
                          <div className="titulo-item w-full text-primary">
                            {g.grupo}
                          </div>
                          <div className="tiempo-item">
                            {elapsedMinutos(g.tiempo)}
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
