import { Navigate, useParams } from "react-router-dom";
import { useGetProcesoActualQuery } from "../../../services/docs";
import { Spinner } from "../../../components/Spinner";
import { useDispatch } from "react-redux";
import { cambiarEtapa } from "../../../features/ui/uiSlice";
import { useEffect } from "react";

export const ProcesoScreen = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams();
  const { data, isLoading } = useGetProcesoActualQuery({
    siniestro,
    tramite: tramite_id,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      if (data.etapaActual) {
        console.log(data);
        dispatch(
          cambiarEtapa({
            etapaActual: data.etapaActual,
            fc_sub_etapa: data.fc_sub_etapa,
          })
        );
      }
    }
  }, [data]);
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  if (!isLoading) {
    if (data) {
      if (data.path) {
        return (
          <Navigate
            to={`/view/siniestro/${siniestro}/${tramite_id}${data.path}`}
          />
        );
      }
      if (!data.path) {
        return (
          <h1 className="mx-auto w-2/3 rounded bg-warning py-1 text-center">
            {data.message}
          </h1>
        );
      }
    }
  }
  return <></>;
};
