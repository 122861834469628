import {
  useCargarCondicionesMutation,
  useGetPendientesQuery,
  useLazyGetPendientesQuery,
  useRmEspecialistaMutation,
} from "../../../services/docs";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../components/Spinner";
import { elapsed, fecha_actual } from "../../../hooks/elapsed";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import { useForm } from "../../../hooks/useForm";

export const CondicionesScreen = () => {
  const { data, isLoading } = useGetPendientesQuery();
  const [cargaAction, { isLoading: isLoadingE }] =
    useCargarCondicionesMutation();
  const { formulario, handleInputChange, handleChange } = useForm({
    producto_id: "",
    condiciones: "",
    kit: "",
  });

  const onChange = (e: any, campo: string) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, campo);
  };
  const getBase64 = (file: any, campo: string) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result, campo);
    };
  };
  const onLoad = (fileString: any, campo: string) => {
    console.log(fileString);
    handleChange(campo, fileString);
  };
  const guardarCondiciones = async () => {
    console.log(formulario);
    const resp = await cargaAction({
      ...formulario,
      producto_id: +formulario.producto_id,
    }).unwrap();
    if (resp) {
      toastr.info("Archivos cargados");
    }
  };
  return (
    <div className="card">
      <div className="space-y-6">
        <div className="mb-3 mt-5 w-full">
          <div className="flex items-center">
            <div className="w-3/4">
              <h6>Cargar condiciones</h6>
            </div>
            <div className="mb-1 w-1/4"></div>
          </div>
          <hr />
        </div>
        <div className="my-2">
          <div className="flex flex-col px-6 py-2">
            <Label className="my-2" htmlFor="fc_valor">
              Producto:
            </Label>
            <Input
              type="text"
              name="producto_id"
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col px-6 py-2">
            <Label className="my-2" htmlFor="fc_valor">
              Condiciones:
            </Label>
            <input
              onChange={(e) => {
                onChange(e, "condiciones");
              }}
              type="file"
            />
          </div>
          <div className="flex flex-col px-6 py-2">
            <Label className="my-2" htmlFor="fc_valor">
              Kit:
            </Label>
            <input
              onChange={(e) => {
                onChange(e, "kit");
              }}
              type="file"
            />
          </div>
          <div className="flex flex-col px-6 py-2">
            <Button onClick={guardarCondiciones}>Guardar</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
