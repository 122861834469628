import { useParams } from "react-router-dom";
import {
  useDocumentoAdicionalMutation,
  useEliminardocAdiMutation,
  useGetNotificacionesQuery,
  useLazyGetDocumentosAdicionalesQuery,
} from "../services/docs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/Acordion";
import { Spinner } from "@phosphor-icons/react";
import { DataTable } from "./EjemploTabla";
import { useForm } from "../hooks/useForm";
import { useEffect } from "react";
import { Label } from "./ui/Label";
import { Input } from "./ui/Input";
import Button from "./ui/Button";
import {toastr} from 'react-redux-toastr'

export const DocumentacionAdicionalComponent = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams();
  const [documentos, { data: doc, isLoading }] =
    useLazyGetDocumentosAdicionalesQuery();
  const [documento, { isLoading: cargandoBoton }] =
    useDocumentoAdicionalMutation();
  const [eliminar, { isLoading: cargandoEliminarBoton }] =
    useEliminardocAdiMutation();
  useEffect(() => {
    documentos({ siniestro, tramite: tramite_id });
  }, []);

  const { formulario, handleInputChange, reset } = useForm({
    tramite_id: +tramite_id,
    fc_siniestro: siniestro,
    fc_nombre_documento: "",
    fc_descripcion_documento: "",
  });
  const eliminarDoc = async (id: number) => {
    const resp = await eliminar({
      tramite_id: +tramite_id,
      fc_siniestro: siniestro,
      id: id,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m)
              });
            }
          }
        }
      });
    if (resp) {
      toastr.info(resp.message)
      documentos({ siniestro, tramite: tramite_id });
    }
  };
  const handleAction = async () => {
    const resp = await documento({
      ...formulario,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m)
              });
            }
          }
        }
      });
    if (resp) {
      toastr.info(resp.message)
      reset({
        tramite_id: +tramite_id,
        fc_siniestro: siniestro,
        fc_nombre_documento: "",
        fc_descripcion_documento: "",
      });
      documentos({ siniestro, tramite: tramite_id });
    }
  };

  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <Accordion type="single" collapsible className="w-full" defaultValue="">
      <AccordionItem value="activos">
        <div className="card">
          <AccordionTrigger>
            <p className="text-1xl">Agregar Documentación Adicional</p>
          </AccordionTrigger>
          <AccordionContent>
            <div className="space-y-4">
              <div className="group space-y-4">
                <div className="flex w-full">
                  <div className="w-1/4">
                    <div className="group">
                      <Label htmlFor="fc_nombre_documento">
                        Nombre Documento
                      </Label>
                      <Input
                        name="fc_nombre_documento"
                        onChange={handleInputChange}
                        value={formulario.fc_nombre_documento}
                      />
                    </div>
                    <div className="group my-3">
                      <Label htmlFor="fc_descripcion_documento">
                        Descripción
                      </Label>
                      <Input
                        name="fc_descripcion_documento"
                        onChange={handleInputChange}
                        value={formulario.fc_descripcion_documento}
                      />
                    </div>
                    <div className="group my-3">
                      {!cargandoBoton && (
                        <Button onClick={handleAction}>
                          Agregar documento
                        </Button>
                      )}
                      {cargandoBoton && <Spinner />}
                    </div>
                  </div>
                  <div className="grid w-3/4 grid-cols-4 place-items-stretch gap-4 px-5">
                    {doc && doc.misDocumentosAdicionales.length === 0 && (
                      <>
                        <p></p>
                        <h1 className="m-auto w-3/4 rounded bg-warning py-1 text-center">
                          No tienes Documentos agregados
                        </h1>
                      </>
                    )}
                    {doc &&
                      doc.misDocumentosAdicionales.length > 0 &&
                      doc.misDocumentosAdicionales.map((d: any) => {
                        return (
                          <div key={d.id} className="card">
                            <div className="flex w-full justify-end">
                              {!cargandoEliminarBoton && (
                                <div
                                  onClick={() => eliminarDoc(d.id)}
                                  className="2xl w-6 cursor-pointer rounded bg-danger text-center text-white"
                                >
                                  X
                                </div>
                              )}
                              {cargandoEliminarBoton && <Spinner />}
                            </div>
                            <h5>{d.fc_nombre_documento}</h5>
                            <hr />
                            <p>{d.fc_descripcion_documento}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </AccordionContent>
        </div>
      </AccordionItem>
    </Accordion>
  );
};
