import { Spinner } from "../../components/Spinner";
import Button from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";
import { fecha_actual } from "../../hooks/elapsed";
import { useForm } from "../../hooks/useForm";
import {
  useVerEmisionDetalleMutation,
  useVerEmisionMutation,
} from "../../services/docs";
import { useState } from "react";
import { toastr } from "react-redux-toastr";

export const VerEmisionesScreen = () => {
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fc_fecha_inicio: "",
    fc_fecha_fin: "",
  });
  const [verEmisionAction, { data, isLoading: loadingVer }] =
    useVerEmisionMutation();
  const [
    verEmisionDetalleAction,
    { data: dataDetalle, isLoading: loadingVerDetalle },
  ] = useVerEmisionDetalleMutation();

  const [cargando, setCargando] = useState(false);

  const verVenta = async () => {
    setCargando(true);
    if (formulario.fc_fecha_fin === "" || formulario.fc_fecha_inicio === "") {
      toastr.error("Tiene que seleccionar un rango de fechas");
      return;
    }
    console.log(formulario);
    const g = await verEmisionAction(formulario).unwrap();
    console.log(g);
    if (g) {
      setCargando(false);
    }
  };
  const cargaRegistros = async (fc_uuid_carga: string, fi_travel: number) => {
    console.log(fc_uuid_carga);
    console.log(fi_travel);
    setCargando(true);
    const g = await verEmisionDetalleAction({
      fc_uuid_carga,
      fi_travel,
    }).unwrap();
    console.log(g);
    if (g) {
      setCargando(false);
    }
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <div className="flex">
            <div className="flex-1"></div>
            <div>
              <p className="text-2xl">
                <Label>Inicial</Label>
                <Input
                  type="date"
                  value={formulario.fc_fecha_inicio}
                  onChange={handleInputChange}
                  name="fc_fecha_inicio"
                />
              </p>
            </div>
            <div>
              <p className="text-2xl">
                <Label>Final</Label>
                <Input
                  type="date"
                  value={formulario.fc_fecha_fin}
                  onChange={handleInputChange}
                  name="fc_fecha_fin"
                />
              </p>
            </div>
            <div>
              <p className="mt-9 text-2xl">
                <Button onClick={verVenta}>Ver</Button>
              </p>
            </div>
          </div>
          <hr className="my-2" />
        </div>
        <div className="mt-10 w-full ">
          {loadingVer && <Spinner />}
          {data && data.emisiones && (
            <div className="block w-full overflow-x-auto">
              <div className="mb-2 grid grid-cols-4 place-items-stretch gap-4">
                {data.emisiones.map((e: any) => {
                  return (
                    <div
                      key={e.c_fc_uuid_carga}
                      className="card border-r-3 cursor-pointer border p-4"
                      onClick={() =>
                        cargaRegistros(e.c_fc_uuid_carga, e.c_fi_travel)
                      }
                    >
                      <div className="flex flex-col">
                        <span className="w-full text-center text-sm font-bold">
                          {e.c_fc_nomenclatura}
                        </span>
                        <div className="flex flex-row">
                          <div className="mx-3">
                            <span className="text-xs">Registros:</span>
                            <span> {e.c_fi_registros} </span>
                          </div>
                          <div className="mx-3">
                            <span className="text-xs">Rechazados:</span>
                            <span> {e.c_fi_rechazados} </span>
                          </div>
                          <div className="mx-3">
                            <span className="text-xs">Emitidos:</span>
                            <span> {e.c_fi_emitidos} </span>
                          </div>
                        </div>
                        <div className="text-center text-sm text-gray">
                          {e.c_fc_estatus_carga}
                        </div>
                        <div className="text-right text-xs text-gray-700">
                          {fecha_actual(e.c_fd_inclusion)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {dataDetalle && dataDetalle.ventas && (
            <div className="block w-full overflow-x-auto">
              <table className="w-full border-collapse items-center bg-transparent ">
                <thead>
                  <tr>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      #
                    </th>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      RFC
                    </th>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      Procesado
                    </th>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      Mensaje
                    </th>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      Llave
                    </th>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      Estatus Póliza
                    </th>
                    <th className="border-blueGray-100 whitespace-nowrap border border-l-0 border-r-0 border-solid bg-primary px-6 py-3 text-left align-middle text-xs font-semibold uppercase text-white">
                      Póliza PDF
                    </th>
                  </tr>
                </thead>
                <tbody className="dark:divide-gray-199 divide-y divide-gray-200">
                  {dataDetalle.ventas.map((e: any, i: number) => {
                    return (
                      <tr
                        key={e.v_fc_uuid_venta}
                        className="cursor-pointer border-b-secondary-ultra-light"
                        onClick={() =>
                          cargaRegistros(e.c_fc_uuid_carga, e.c_fi_travel)
                        }
                      >
                        <th className="text-blueGray-700 whitespace-nowrap border-l-0 border-r-0 border-t-0 p-2 px-2 text-left align-middle text-xs ">
                          <span className="flex flex-col">{i + 1}</span>
                        </th>
                        <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 text-xs">
                          <span className="flex flex-col">
                            {e.v_fc_rfc_asegurado}
                          </span>
                        </td>
                        <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                          <span className="flex flex-col">
                            {e.v_fi_procesado}
                          </span>
                        </td>
                        <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                          <span className="flex flex-col">
                            {e.v_fc_descripcion_error}
                          </span>
                        </td>
                        <td className="align-center whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-xs">
                          <span className="flex flex-col">
                            {e.ad_fi_numero_cliente}_{e.ad_fi_numero_producto}_
                            {e.ad_fc_numero_poliza}
                          </span>
                        </td>
                        <td className="whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 align-middle text-xs">
                          {e.p_fc_estatus_poliza}
                        </td>
                        <td className="whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 align-middle text-xs">
                          {e.p_fc_url_poliza}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
