import { useForm } from "../../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCatRespuestasQuery,
  useDictamenMutation,
} from "../../../services/docs";
import { useEffect, useState } from "react";
import { DocumentacionAdicionalComponent } from "../../../components/DocumentacionAdicionalComponent";
import { Spinner } from "@phosphor-icons/react";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const AseguradoraScreen = () => {
  const { siniestro, tramite_id } = useParams();
  const { data, isLoading } = useCatRespuestasQuery({
    siniestro,
    tramite: tramite_id,
  });
  const [fi_sub_etapa, setFi] = useState(0);
  const [dictamen, { isLoading: cargandoBoton }] = useDictamenMutation();
  const [respuesta, setRespuesta] = useState(0);
  const navigate = useNavigate();
  const {
    formulario,
    handleInputChange,
    handleSelectChange,
    handleTexareChange,
    handleChange,
  } = useForm({
    fi_respuesta_aseguradora: 0,
    fc_comentarios_dictamen: "",
    carta_dictamen: "",
    fn_monto_pagado: 0,
    fd_fecha_pago: "01-01-1999",
    fi_motivos_rechazo: 0,
    tramite_id: Number(tramite_id),
    siniestro: siniestro,
    fc_clave_aseguradora: "",
  });
  const { fi_respuesta_aseguradora } = formulario;
  useEffect(() => {
    if (data && fi_respuesta_aseguradora) {
      let res = data.respuestas.filter(
        (r: any) => r.id === Number(fi_respuesta_aseguradora)
      );
      if (res.length > 0) {
        setFi(res[0].fi_sub_etapa);
      }
    }
  }, [fi_respuesta_aseguradora]);
  useEffect(() => {
    setRespuesta(+fi_respuesta_aseguradora);
    if (+fi_respuesta_aseguradora !== 1) {
      formulario.fn_monto_pagado = 0;
      formulario.fd_fecha_pago = "01-01-1999";
    }
  }, [fi_respuesta_aseguradora]);
  const onChange = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const onLoad = (fileString: any) => {
    handleChange("carta_dictamen", fileString);
  };
  const handleAction = async () => {
    console.log(formulario);
    const resp = await dictamen({
      ...formulario,
      tramite_id: +tramite_id,
      fi_respuesta_aseguradora: +formulario.fi_respuesta_aseguradora,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        } else {
          toastr.error(err.data.message);
        }
      });
    if (resp) {
      toastr.info(resp.message);
      navigate("/view/siniestro/" + siniestro + "/" + tramite_id + "/");
    }
  };
  return (
    <>
      <div className="flex h-10 w-full"></div>
      {fi_sub_etapa === 3 && (
        <div className="w-full">
          <DocumentacionAdicionalComponent />
        </div>
      )}
      <div className="grid grid-cols-3 place-items-stretch gap-6">
        {!isLoading && data && (
          <>
            <div>
              <Label htmlFor="respuesta">Respuesta:</Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fi_respuesta_aseguradora"
                onChange={handleSelectChange}
              >
                <option value={0}>Respuesta</option>
                {data.respuestas &&
                  data.respuestas.map((r: any) => {
                    return (
                      <option key={r.id} value={Number(r.id)}>
                        {r.fc_respuesta}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="flex flex-col">
              <Label htmlFor="">Comentario:</Label>
              <textarea
                name="fc_comentarios_dictamen"
                onChange={handleTexareChange}
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
              ></textarea>
            </div>
            {fi_sub_etapa === 1 && (
              <>
                <div className="flex flex-col">
                  <Label htmlFor="">Monto Pagado:</Label>
                  <Input
                    type="text"
                    name="fn_monto_pagado"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex flex-col">
                  <Label htmlFor="">Fecha Pago:</Label>
                  <Input
                    type="date"
                    name="fd_fecha_pago"
                    id="fd_fecha_pago"
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
            <div className="flex flex-col">
              <Label htmlFor="">Carta Dictamen:</Label>
              <Input type="file" name="carta" onChange={onChange} />
            </div>
            <div className="flex flex-col ">
              <Label htmlFor="">Clave Aseguradora</Label>
              <Input
                type="text"
                name="fc_clave_aseguradora"
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col ">
              {!cargandoBoton && (
                <Button onClick={handleAction}>Guardar Dictamen</Button>
              )}
              {cargandoBoton && <Spinner />}
            </div>
          </>
        )}
      </div>
    </>
  );
};
