export const ItemDato = (prop: { titulo: string; subtitulo: string }) => {
  return (
    <div className="flex text-primary">
      <div className="flex flex-col gap-0 truncate">
        <span className="block">{prop.titulo}</span>
        <span className="block text-dark">{prop.subtitulo}</span>
      </div>
    </div>
  );
};
