import { useParams } from "react-router-dom";
import { useGetSemaforoQuery } from "../../../services/docs";
import { Spinner } from "../../../components/Spinner";
import { DataTable } from "../../../components/EjemploTabla";
import { elapsedMinutos } from "../../../hooks/elapsed";
import { Fragment } from "react";
import { TiemposComponent } from "../../../components/TiemposComonent";

const columns: any[] = [
  {
    accessorKey: "ttes_fc_siniestro",
    header: "Siniestro",
  },
  {
    accessorKey: "ttes_fi_tramite_id",
    header: "Tramite",
  },
  {
    accessorKey: "ttes_fi_etapa",
    header: "Etapa",
  },
  {
    accessorKey: "ttes_fc_nombre_etapa",
    header: "Nombre Etapa",
  },
  {
    accessorKey: "ttes_fd_fecha_inicial",
    header: "Fecha Inicial",
  },
  {
    accessorKey: "ttes_fi_minutos_transcurridos",
    header: "Tiempo",
  },
  {
    accessorKey: "ttes_fi_movimiento",
    header: "Movimiento",
  },
];
export const TiemposScreen = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams();
  const { data, isLoading } = useGetSemaforoQuery({
    siniestro,
    tramite: tramite_id,
  });
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="grid grid-cols-1 place-items-stretch gap-4">
        {data && data.semaforo && data.semaforo.length > 0 && (
          <>
            <TiemposComponent data={data} />
          </>
        )}
      </div>
    </>
  );
};
