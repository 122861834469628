import React from 'react';

export interface ILogoProps { }

export const Logo = (): JSX.Element => {
  return (
    <svg
      className="shrink-0 relative overflow-visible aspect-auto h-auto w-32"
      style={{}}
      width="674"
      height="630"
      viewBox="0 0 674 630"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M333.385 146.87C335.957 146.87 338.528 146.87 341.1 146.87C358.619 147.352 376.138 151.371 392.532 158.603C406.998 165.193 420.499 174.354 432.393 186.087C458.109 211.804 471.128 245.235 471.77 278.826V150.406C471.77 147.835 471.77 145.263 471.77 142.691C471.288 125.172 467.27 107.653 460.037 91.2591C453.608 76.7937 444.286 63.2927 432.553 51.399C379.996 -1.15845 294.972 -1.15845 242.414 51.399C215.573 78.2402 202.554 113.6 203.036 148.799V146.549H333.385V146.87Z"
        fill="#231F20"
      />
      <path
        d="M471.61 278.987V281.237H341.1C338.528 281.237 335.957 281.237 333.385 281.237C315.866 280.755 298.347 276.737 281.953 269.504C267.487 263.075 253.986 253.753 242.093 242.02C216.377 216.304 203.358 182.873 202.715 149.281V277.541C202.715 280.112 202.715 282.684 202.715 285.255C203.197 302.775 207.215 320.294 214.448 336.688C220.877 351.153 230.199 364.654 241.932 376.548C294.489 429.105 379.514 429.105 432.071 376.548C459.073 349.546 472.092 314.186 471.61 278.987Z"
        fill="#00BABC"
      />
      <path
        d="M281.953 269.504C298.347 276.898 315.705 280.755 333.385 281.237C335.957 281.237 338.528 281.237 341.1 281.237H471.61V278.987C470.967 245.395 457.948 211.804 432.232 186.248C420.338 174.354 406.837 165.193 392.372 158.764C375.978 151.371 358.619 147.513 340.939 147.031C338.368 147.031 335.796 147.031 333.224 147.031H202.715V149.281C203.358 182.873 216.377 216.465 242.093 242.02C253.986 253.753 267.487 262.915 281.953 269.504ZM337.243 170.497C361.351 170.497 380.799 189.945 380.799 214.054C380.799 238.163 361.351 257.611 337.243 257.611C313.134 257.611 293.525 238.163 293.525 214.054C293.525 189.945 313.134 170.497 337.243 170.497Z"
        fill="#007B98"
      />
      <path
        d="M337.244 257.611C361.3 257.611 380.801 238.11 380.801 214.054C380.801 189.998 361.3 170.497 337.244 170.497C313.189 170.497 293.688 189.998 293.688 214.054C293.688 238.11 313.189 257.611 337.244 257.611Z"
        fill="white"
      />
      <path
        d="M0.200195 532.934H10.3259V463.019H0.200195V447.107H38.2923V463.019H28.1665V532.934H38.2923V548.846H0.200195V532.934Z"
        fill="#00BABC"
      />
      <path
        d="M55.6511 494.36C55.6511 492.271 54.526 491.146 52.4365 491.146H46.6504V475.716H63.3659C69.795 475.716 73.0095 478.77 73.0095 483.913V486.002C73.0095 487.61 72.688 489.217 72.688 489.217H73.0095C76.224 483.109 84.0996 473.948 98.8864 473.948C115.12 473.948 124.442 482.466 124.442 501.753V530.363C124.442 532.291 125.567 533.577 127.656 533.577H133.442V548.846H116.084C109.173 548.846 106.28 545.953 106.28 539.042V505.45C106.28 496.61 104.03 490.503 94.8682 490.503C85.0639 490.503 77.8313 496.61 75.2596 505.45C74.2953 508.504 73.8131 511.718 73.8131 515.254V548.846H55.8118L55.6511 494.36Z"
        fill="#00BABC"
      />
      <path
        d="M148.068 525.541C148.068 525.541 157.39 536.31 169.927 536.31C175.552 536.31 179.731 534.059 179.731 529.077C179.731 518.79 141.157 518.951 141.157 495.324C141.157 480.698 154.337 473.948 169.606 473.948C179.41 473.948 195.322 477.162 195.322 489.217V496.771H179.249V493.235C179.249 489.86 174.106 488.092 170.088 488.092C163.659 488.092 159.158 490.342 159.158 494.682C159.158 506.093 198.054 503.843 198.054 528.273C198.054 542.096 185.839 550.614 169.927 550.614C149.836 550.614 139.55 537.595 139.55 537.595L148.068 525.541Z"
        fill="#00BABC"
      />
      <path
        d="M214.608 494.36C214.608 492.271 213.483 491.146 211.394 491.146H205.607V475.716H223.127C229.877 475.716 232.77 478.77 232.77 485.359V519.112C232.77 527.952 235.02 534.059 244.021 534.059C257.2 534.059 264.594 522.487 264.594 509.468V475.877H282.756V530.523C282.756 532.452 283.881 533.738 285.97 533.738H291.757V549.007H275.041C268.773 549.007 265.397 545.953 265.397 540.81V538.881C265.397 537.113 265.558 535.667 265.558 535.667H265.237C261.862 543.221 252.539 550.936 240.003 550.936C224.412 550.936 214.769 543.06 214.769 523.13L214.608 494.36Z"
        fill="#00BABC"
      />
      <path
        d="M306.384 494.36C306.384 492.271 305.259 491.146 303.17 491.146H297.384V475.716H314.099C320.528 475.716 323.904 478.448 323.904 484.556V488.895C323.904 491.628 323.582 493.557 323.582 493.557H323.904C327.118 483.109 335.958 474.752 347.209 474.752C348.816 474.752 350.423 475.073 350.423 475.073V492.914C350.423 492.914 348.495 492.592 345.923 492.592C338.208 492.592 329.208 497.092 325.993 507.861C325.029 511.397 324.386 515.415 324.386 519.755V548.686H306.384V494.36Z"
        fill="#00BABC"
      />
      <path
        d="M397.516 502.879H399.766V502.236C399.766 491.788 395.587 487.931 386.265 487.931C383.05 487.931 376.461 488.735 376.461 492.753V496.61H359.584V488.895C359.584 475.234 378.872 473.787 386.426 473.787C410.856 473.787 417.928 486.485 417.928 502.879V530.202C417.928 532.131 419.053 533.417 421.143 533.417H426.929V548.686H410.535C403.784 548.686 401.212 544.989 401.212 540.649C401.212 538.721 401.373 537.435 401.373 537.435H401.052C401.052 537.435 395.426 550.293 379.032 550.293C366.013 550.293 354.12 542.096 354.12 527.47C353.959 504.807 385.461 502.879 397.516 502.879ZM383.372 536.31C393.176 536.31 400.087 526.023 400.087 517.022V515.254H396.873C387.39 515.254 372.443 516.54 372.443 526.666C372.443 531.649 375.979 536.31 383.372 536.31Z"
        fill="#00BABC"
      />
      <path
        d="M441.394 494.36C441.394 492.271 440.269 491.146 438.18 491.146H432.394V475.716H449.109C455.538 475.716 458.753 478.77 458.753 483.913V486.002C458.753 487.61 458.431 489.217 458.431 489.217H458.753C461.967 483.109 469.843 473.948 484.63 473.948C500.863 473.948 510.185 482.466 510.185 501.753V530.363C510.185 532.291 511.31 533.577 513.399 533.577H519.186V548.846H501.827C494.916 548.846 492.023 545.953 492.023 539.042V505.45C492.023 496.61 489.773 490.503 480.611 490.503C470.807 490.503 463.574 496.61 461.003 505.45C460.038 508.504 459.556 511.718 459.556 515.254V548.846H441.555L441.394 494.36Z"
        fill="#00BABC"
      />
      <path
        d="M566.277 473.948C575.759 473.948 592.796 477.805 592.796 490.503V499.182H576.724V495.164C576.724 491.146 570.616 489.378 566.277 489.378C553.579 489.378 544.579 499.021 544.579 512.201C544.579 527.148 555.669 534.702 567.402 534.702C579.938 534.702 589.1 524.898 589.1 524.898L596.654 537.595C596.654 537.595 585.725 550.775 565.794 550.775C541.686 550.775 526.256 533.738 526.256 512.522C526.256 491.306 541.203 473.948 566.277 473.948Z"
        fill="#00BABC"
      />
      <path
        d="M640.533 473.948C661.427 473.948 672.999 489.217 672.999 508.665C672.999 510.754 672.517 515.415 672.517 515.415H622.049C623.496 528.273 632.979 535.024 644.069 535.024C655.802 535.024 664.642 526.827 664.642 526.827L672.196 539.524C672.196 539.524 660.945 550.614 642.783 550.614C618.513 550.614 603.566 533.095 603.566 512.361C603.405 489.699 618.674 473.948 640.533 473.948ZM654.355 502.718C654.034 494.039 648.087 487.931 640.533 487.931C631.211 487.931 624.621 493.556 622.531 502.718H654.355Z"
        fill="#00BABC"
      />
      <path
        d="M207.697 603.172C207.697 603.172 211.715 608.636 219.109 608.636C224.413 608.636 227.627 605.743 227.627 602.207C227.627 594.01 208.34 595.457 208.34 581.152C208.34 571.991 216.537 566.044 226.181 566.044C232.61 566.044 240.325 568.776 240.325 574.241C240.325 574.884 240.325 575.848 240.003 576.652L239.36 580.188H231.324L231.645 578.741C231.806 578.259 231.806 577.777 231.806 577.295C231.806 575.045 229.074 574.08 225.859 574.08C220.395 574.08 217.341 576.973 217.341 580.188C217.341 588.224 236.789 586.135 236.789 600.922C236.789 609.761 229.877 616.673 218.787 616.673C207.697 616.673 201.911 609.279 201.911 609.279L207.697 603.172Z"
        fill="#231F20"
      />
      <path
        d="M262.344 579.866C271.827 579.866 276.488 585.492 276.488 593.207C276.488 596.26 275.202 599.796 275.202 599.796H250.933C250.933 599.796 250.772 600.6 250.772 601.404C250.772 606.065 253.986 609.44 259.612 609.44C265.237 609.44 270.541 605.583 270.541 605.583L272.952 612.333C272.952 612.333 266.844 616.833 259.13 616.833C248.04 616.833 242.093 610.244 242.093 601.564C241.771 589.831 250.45 579.866 262.344 579.866ZM267.648 593.849C267.648 593.849 267.648 593.207 267.648 592.564C267.648 588.867 265.398 586.778 262.023 586.778C257.522 586.778 253.986 589.831 252.218 593.849H267.648Z"
        fill="#231F20"
      />
      <path
        d="M285.328 589.831C285.489 589.028 285.328 588.224 284.042 588.224H281.31L282.757 580.831H290.793C293.525 580.831 294.811 581.795 294.811 583.885C294.811 584.849 294.168 587.903 293.686 589.51H293.847C296.258 584.527 301.24 580.349 306.866 580.349C307.508 580.349 308.151 580.509 308.473 580.509L306.866 589.189C306.705 589.189 305.901 589.028 304.776 589.028C299.311 589.028 293.365 593.85 291.757 602.689L289.186 616.191H280.346L285.328 589.831Z"
        fill="#231F20"
      />
      <path
        d="M311.528 589.671C311.367 588.706 311.046 588.224 309.92 588.224H308.956L310.403 580.831H315.064C317.475 580.831 319.082 582.277 319.403 584.527L322.457 602.69C322.778 604.618 322.939 607.672 322.939 607.672H323.1C323.1 607.672 324.225 604.618 325.189 602.69L335.476 584.206C336.762 581.795 338.208 580.831 341.101 580.831H346.727L345.28 588.224H344.316C343.351 588.224 342.709 588.546 342.226 589.51L326.957 616.03H316.349L311.528 589.671Z"
        fill="#231F20"
      />
      <path
        d="M347.691 611.047L351.709 590.796C351.87 590.153 351.87 589.831 351.87 589.189C351.87 588.546 351.388 588.224 350.584 588.224H347.852L349.298 580.831H357.335C360.228 580.831 361.513 581.795 361.513 584.206C361.513 584.849 361.353 585.653 361.192 586.295L357.174 606.547C357.174 606.868 357.013 607.351 357.013 607.511C357.013 608.154 357.335 608.636 358.299 608.636H361.031L359.585 616.03H351.548C348.816 616.03 347.53 614.744 347.53 612.655C347.53 612.172 347.53 611.851 347.691 611.047ZM356.852 566.848H364.407L362.799 574.884H355.245L356.852 566.848Z"
        fill="#231F20"
      />
      <path
        d="M388.193 579.866C393.497 579.866 402.658 581.795 401.211 588.706L400.568 591.921H393.014L393.175 590.796C393.657 588.385 390.603 587.26 387.55 587.26C380.799 587.26 375.495 593.367 375.495 600.118C375.495 605.1 378.388 609.279 384.817 609.279C391.246 609.279 395.747 605.261 395.747 605.261L398.158 612.012C398.158 612.012 393.336 616.994 383.853 616.994C372.602 616.994 366.655 609.922 366.655 601.082C366.655 589.831 375.817 579.866 388.193 579.866Z"
        fill="#231F20"
      />
      <path
        d="M424.517 579.866C434 579.866 438.661 585.492 438.661 593.207C438.661 596.26 437.375 599.796 437.375 599.796H413.105C413.105 599.796 412.945 600.6 412.945 601.404C412.945 606.065 416.159 609.44 421.785 609.44C427.41 609.44 432.714 605.583 432.714 605.583L435.125 612.333C435.125 612.333 429.017 616.833 421.302 616.833C410.212 616.833 404.265 610.244 404.265 601.564C403.944 589.831 412.623 579.866 424.517 579.866ZM429.821 593.849C429.821 593.849 429.821 593.207 429.821 592.564C429.821 588.867 427.571 586.778 424.196 586.778C419.695 586.778 416.159 589.831 414.391 593.849H429.821Z"
        fill="#231F20"
      />
      <path
        d="M443.804 605.1C443.804 605.1 447.822 610.083 454.251 610.083C457.466 610.083 459.716 608.636 459.716 606.386C459.716 603.975 456.983 602.85 454.251 601.886C449.59 600.118 443.965 597.707 443.965 590.956C443.965 583.402 451.197 579.866 458.43 579.866C464.538 579.866 472.092 582.277 470.967 588.224L470.484 590.956H462.93L463.091 589.831C463.412 588.063 461.484 586.778 458.269 586.778C455.055 586.778 452.805 588.385 452.805 590.474C452.805 593.207 456.18 594.332 459.073 595.457C463.573 597.225 468.716 599.314 468.716 605.583C468.716 612.494 462.287 616.994 454.09 616.994C444.286 616.994 439.625 610.886 439.625 610.886L443.804 605.1Z"
        fill="#231F20"
      />
    </svg>
  );
};
