import { Spinner } from "../../../components/Spinner";
import {
  useGenerarReporteMutation,
  useGetReportesQuery,
} from "../../../services/docs";
import { useForm } from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { TablaTramitesComponent } from "../../../components/TablaTramitesComponent";
import { ReportesComponent } from "../../../components/ReportesComponet";

export const ReportesScreen = () => {
  const { data, isLoading } = useGetReportesQuery();
  const navigate = useNavigate();
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fi_reporte: "",
    fc_inicial: "",
    fc_final: "",
  });
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`);
  };
  const [generarAction, { data: reporte, error, isLoading: reporteLoading }] =
    useGenerarReporteMutation();
  const generarReporte = async () => {
    if (+formulario.fi_reporte === 0) {
      toastr.error("Debe seleccionar un tipo de reporte");
      return;
    }
    if (formulario.fc_inicial === "") {
      toastr.error("Debes de agregar un fecha Inicial");
      return;
    }
    if (formulario.fc_final === "") {
      toastr.error("Debes de agregar un fecha Final");
      return;
    }
    await generarAction({
      ...formulario,
      fi_reporte: +formulario.fi_reporte,
    }).unwrap();
  };
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="card">
        <div className="w-full text-center">
          <h1 className="text-1xl">Reportes</h1>
          <hr className="my-2" />
        </div>
        <div className="grid grid-cols-4 place-items-stretch gap-4">
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="fi_busqueda_id" className="my-2">
                Tipo Reporte:
              </Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fi_reporte"
                onChange={handleSelectChange}
              >
                <option value="">Selecciona</option>
                {data &&
                  data.reportes &&
                  data.reportes.map((b: any) => {
                    return (
                      <option key={b.id} value={b.id}>
                        {b.fc_nombre_reporte}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label className="my-2" htmlFor="fc_valor">
                Fecha Inicial:
              </Label>
              <Input
                type="date"
                name="fc_inicial"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label className="my-2" htmlFor="fc_valor">
                Fecha Final:
              </Label>
              <Input type="date" name="fc_final" onChange={handleInputChange} />
            </div>
          </div>
          <div>
            <div className="mt-6 flex flex-col px-6 py-2">
              <Button onClick={generarReporte}>Generar</Button>
            </div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 place-items-stretch gap-4">
          {reporteLoading && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          )}
          {!reporteLoading &&
            reporte &&
            reporte.reporte &&
            reporte.reporte.length === 0 && (
              <div className="my-4 rounded bg-primary py-2 text-center text-white">
                No se encontraron datos
              </div>
            )}
          {!reporteLoading &&
            reporte &&
            reporte.reporte &&
            reporte.reporte.length > 0 && (
              <ReportesComponent data={reporte.reporte} />
            )}
        </div>
      </div>
    </>
  );
};
