import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import uiReducer from "./features/ui/uiSlice";
import { docsApi } from "./services/docs";
import { configApi } from "./services/config.service";
import { reducer as toastrReducer } from "react-redux-toastr";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(docsApi.middleware, configApi.middleware),
  reducer: {
    ui: uiReducer,
    toastr: toastrReducer,
    [docsApi.reducerPath]: docsApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
