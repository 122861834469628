import { useParams } from "react-router-dom";
import { useGetFinalizadoQuery } from "../../../services/docs";
import { Spinner } from "../../../components/Spinner";

export const FinalizadoScreen = () => {
  const { siniestro, tramite_id = 0 } = useParams();
  const { data, isLoading } = useGetFinalizadoQuery({
    siniestro,
    tramite: tramite_id,
  });
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="">
      <div className="card grid gap-4 bg-white">
        {data && data.finalizado && data.finalizado[0].fi_rechazo !== 1 && (
          <>
            <div className="grid grid-cols-1 gap-6 text-left lg:grid-cols-2">
              <div className="space-y-6">
                <p className="text-xl @lg:text-xl @xl:text-2xl">
                  El tramite esta Finalizado se dictamino como:
                </p>
                <p className="text-3xl @lg:text-3xl @xl:text-3xl">
                  {data.finalizado[0].d_fc_respuesta_aseguradora}
                </p>
                {data.finalizado[0].d_fi_respuesta_aseguradora === 1 && (
                  <span className="block text-base">
                    Monto pagado: {data.finalizado[0].d_fn_monto_pagado}
                  </span>
                )}
                <p className="block text-base">Comentarios Dictamen:</p>
                <p className="block text-base">
                  {data.finalizado[0].d_fc_comentarios_dictamen}
                </p>
              </div>
              <div className="space-y-6 ">
                <p className="mt-5 block text-center text-base">
                  <a
                    className="bg-primary px-4 py-2 text-sm text-primary text-white focus-visible:ring-primary-ultra-light"
                    href={data.finalizado[0].d_fc_url_carta}
                    target="_blank"
                  >
                    Ver carta dictamen
                  </a>
                </p>
              </div>
            </div>
          </>
        )}

        {data && data.finalizado && data.finalizado[0].fi_rechazo === 1 && (
          <div className="grid grid-cols-1 gap-6 text-left lg:grid-cols-2">
            <p className="text-xl @lg:text-xl @xl:text-2xl">
              El tramite esta Eliminado:
            </p>
            <p className="block flex flex-col text-base">
              <span className="w-full text-gray-500">
                {data.finalizado[0].fc_sub_etapa}
              </span>
              {data.finalizado[0].t_fc_motivo_eliminacion}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
