import { Spinner } from "../../components/Spinner";
import {
  useBuscarTramiteMutation,
  useGenerarReporteEmisionMutation,
  useGetReportesEmisionQuery,
} from "../../services/docs";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { Label } from "../../components/ui/Label";
import { Input } from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const ReportesEmisionesScreen = () => {
  const { data, isLoading } = useGetReportesEmisionQuery();
  const navigate = useNavigate();
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fi_tipo_reporte: 0,
    fc_fecha_inicio: "",
    fc_fecha_fin: "",
    fc_nomenclatura: "",
    fi_id: "",
    fc_valor: "",
  });
  const [generarReporteAction, { isLoading: IsLoadingBusqueda, error }] =
    useGenerarReporteEmisionMutation();
  const generarReporte = async () => {
    console.log("f", formulario);
    if (+formulario.fi_tipo_reporte === 0) {
      toastr.error("Debe seleccionar un tipo de Reporte");
      return;
    }
    if (
      +formulario.fi_tipo_reporte === 1 &&
      (formulario.fc_fecha_inicio === "" || formulario.fc_fecha_fin === "")
    ) {
      toastr.error("Debes de agregar Fecha Incial y Final del reporte");
      return;
    }
    if (+formulario.fi_tipo_reporte > 1 && formulario.fc_valor === "") {
      toastr.error("Debes de agregar un valor de busqueda de Reporte");
      return;
    }
    formulario.fc_nomenclatura =
      +formulario.fi_tipo_reporte === 2 ? formulario.fc_valor : "";
    formulario.fi_id =
      +formulario.fi_tipo_reporte === 3 ? formulario.fc_valor : "0";
    delete formulario.fc_valor;
    const reporte = await generarReporteAction({
      ...formulario,
      fi_tipo_reporte: +formulario.fi_tipo_reporte,
      fi_id: +formulario.fi_id,
    })
      .unwrap()
      .catch((err) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (reporte.archivo) {
      let archivo = document.querySelector("#reporte");
      let enlaceTemporal = document.createElement("a");
      enlaceTemporal.href = reporte.archivo;
      enlaceTemporal.target = "_blank";
      archivo.appendChild(enlaceTemporal);
      enlaceTemporal.click();
      archivo.removeChild(enlaceTemporal);
    }
  };
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div id="reporte"></div>
      <div className="card">
        <div className="w-full">
          <h1 className="text-2xl">Reportes de Emisiones</h1>
          <hr className="my-2" />
        </div>
        <div className="grid grid-cols-4 place-items-stretch gap-4">
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="fi_tipo_reporte" className="my-2">
                Tipo Búsqueda:
              </Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fi_tipo_reporte"
                onChange={handleSelectChange}
              >
                <option value="">Selecciona</option>
                {data &&
                  data.reportes &&
                  data.reportes.map((b: any) => {
                    return (
                      <option key={b.id} value={b.id}>
                        {b.fc_tipo_reporte}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {+formulario.fi_tipo_reporte > 1 && (
            <>
              <div className="flex flex-col px-6 py-2">
                <Label className="my-2" htmlFor="fc_valor">
                  Valor a buscar:
                </Label>
                <Input
                  type="text"
                  name="fc_valor"
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}
          {+formulario.fi_tipo_reporte === 1 && (
            <>
              <div className="flex flex-col px-6 py-2">
                <Label className="my-2" htmlFor="fc_valor">
                  Fecha Inicial
                </Label>
                <Input
                  type="date"
                  name="fc_fecha_inicio"
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col px-6 py-2">
                <Label className="my-2" htmlFor="fc_valor">
                  Fecha Final
                </Label>
                <Input
                  type="date"
                  name="fc_fecha_fin"
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          <div>
            <div className="mt-6 flex flex-col px-6 py-2">
              <Button onClick={generarReporte}>Generar</Button>
            </div>
          </div>
        </div>
        {IsLoadingBusqueda && (
          <div className="mt-3.5 flex w-full justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};
