import { Spinner } from "../../components/Spinner";
import {
  useConsultaEmisionMutation,
  useGetTipoConsultaEmisionMutation,
} from "../../services/docs";
import { useForm } from "../../hooks/useForm";
import { DataTable } from "../../components/EjemploTabla";
import { useEffect } from "react";
import { Label } from "../../components/ui/Label";
import { Input } from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import { toastr } from "react-redux-toastr";

const columnsTravel: any[] = [
  {
    accessorKey: "v_fc_tipo_producto",
    header: "Tipo",
  },
  {
    accessorKey: "v_fc_nombre_asegurado",
    header: "Nombre Asegurado",
  },
  {
    accessorKey: "v_fc_paterno_asegurado",
    header: "A. Paterno Asegurado",
  },
  {
    accessorKey: "v_fc_materno_asegurado",
    header: "A. Materno Asegurado",
  },
  {
    accessorKey: "v_fc_correo_asegurado",
    header: "Correo",
  },
  {
    accessorKey: "v_fc_rfc_asegurado",
    header: "RFC",
  },
  {
    accessorKey: "v_fc_fecha_nacimiento_asegurado",
    header: "Fecha Nacimiento",
  },
  {
    accessorKey: "v_fc_nombre_archivo",
    header: "Archivo",
  },
  {
    accessorKey: "v_fi_procesado",
    header: "Estatus de Carga",
  },
  {
    accessorKey: "v_fc_descripcion_error",
    header: "Mensaje Error",
  },
  {
    accessorKey: "fc_email",
    header: "Correo Envió",
  },
  {
    accessorKey: "fi_estatus",
    header: "Estatus Envió",
  },
  {
    accessorKey: "fc_mensaje_envio",
    header: "Mensaje Envió",
  },
  {
    accessorKey: "fd_registro_envio",
    header: "Mensaje Envió",
  },
  {
    accessorKey: "p_fi_numero_producto",
    header: "Producto",
  },
  {
    accessorKey: "p_fc_numero_poliza",
    header: "Póliza Interna",
  },
  {
    accessorKey: "p_fi_numero_cliente",
    header: "Cliente",
  },
  {
    accessorKey: "p_fc_estatus_poliza",
    header: "Estatus Póliza",
  },
  {
    accessorKey: "pa_fc_valor_item",
    header: "Póliza Aseguradora",
  },
  {
    accessorKey: "action",
    header: "PDF",
  },
];
const columnsNoTravel: any[] = [
  {
    accessorKey: "v_fi_numero_venta",
    header: "#Venta",
  },
  {
    accessorKey: "v_fc_nombre_asegurado",
    header: "Asegurado",
  },
  {
    accessorKey: "v_fc_correo",
    header: "Correo",
  },
  {
    accessorKey: "v_fc_rfc_asegurado",
    header: "RFC",
  },
  {
    accessorKey: "v_fc_fecha_nacimiento",
    header: "Fecha Nacimiento",
  },
  {
    accessorKey: "v_fc_nombre_archivo",
    header: "Archivo",
  },
  {
    accessorKey: "v_fi_procesado",
    header: "Estatus de Carga",
  },
  {
    accessorKey: "v_fc_descripcion_error",
    header: "Mensaje Error",
  },
  {
    accessorKey: "fc_email",
    header: "Correo Envió",
  },
  {
    accessorKey: "fi_estatus",
    header: "Estatus Envió",
  },
  {
    accessorKey: "fc_mensaje_envio",
    header: "Mensaje Envió",
  },
  {
    accessorKey: "fd_registro_envio",
    header: "Mensaje Envió",
  },
  {
    accessorKey: "p_fi_numero_producto",
    header: "Producto",
  },
  {
    accessorKey: "p_fc_numero_poliza",
    header: "Póliza Interna",
  },
  {
    accessorKey: "p_fi_numero_cliente",
    header: "Cliente",
  },
  {
    accessorKey: "p_fc_estatus_poliza",
    header: "Estatus Póliza",
  },
  {
    accessorKey: "pa_fc_valor_item",
    header: "Póliza Aseguradora",
  },
  {
    accessorKey: "action",
    header: "PDF",
  },
];
let columns = [];
export const ConsultarEmisionesScreen = () => {
  const [tipoConsultaAction, { data: tipoConsulta, isLoading: loadingTC }] =
    useGetTipoConsultaEmisionMutation();
  const [ConsultaAction, { data: consulta, isLoading: loadingC }] =
    useConsultaEmisionMutation();
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fi_tipo_venta: 0,
    fi_busqueda_id: 0,
    fc_valor: "",
  });
  useEffect(() => {
    if (formulario.fi_tipo_venta > 0) {
      columns =
        +formulario.fi_tipo_venta === 1
          ? [...columnsNoTravel]
          : [...columnsTravel];
      tipoConsultaAction({ fi_tipo_venta: +formulario.fi_tipo_venta });
    }
  }, [formulario.fi_tipo_venta]);

  const buscarTramite = async () => {
    if (+formulario.fi_tipo_venta === 0) {
      toastr.error("Debe seleccionar un tipo de venta");
      return;
    }
    if (+formulario.fi_busqueda_id === 0) {
      toastr.error("Debe seleccionar un tipo de búsqueda");
      return;
    }
    if (formulario.fc_valor === "") {
      toastr.error("Debes de agregar un valor de busqueda");
      return;
    }
    await ConsultaAction({
      ...formulario,
      fi_busqueda_id: +formulario.fi_busqueda_id,
      fi_tipo_venta: +formulario.fi_tipo_venta,
    }).unwrap();
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <h1 className="text-2xl">Buscar Emisión</h1>
          <hr className="my-2" />
        </div>
        <div className="grid grid-cols-3 place-items-stretch gap-4">
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="fi_tipo_venta" className="my-2">
                Tipo Venta:
              </Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fi_tipo_venta"
                onChange={handleSelectChange}
              >
                <option value="">Selecciona</option>
                <option value="1">No Travel</option>
                <option value="2">Tavel</option>
              </select>
            </div>
          </div>
          {loadingTC && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          )}
          {!loadingTC &&
            formulario.fi_tipo_venta > 0 &&
            tipoConsulta &&
            tipoConsulta.reportes && (
              <div>
                <div className="flex flex-col px-6 py-2">
                  <Label htmlFor="fi_busqueda_id" className="my-2">
                    Tipo Consulta:
                  </Label>
                  <select
                    className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                    name="fi_busqueda_id"
                    onChange={handleSelectChange}
                  >
                    <option value="">Selecciona</option>
                    {tipoConsulta.reportes.map((b: any) => {
                      return (
                        <option key={b.id} value={b.id}>
                          {b.fc_nombre_busqueda}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label className="my-2" htmlFor="fc_valor">
                Valor a buscar:
              </Label>
              <Input type="text" name="fc_valor" onChange={handleInputChange} />
            </div>
          </div>
          <div>
            <div className="mt-6 flex flex-col px-6 py-2">
              <Button onClick={buscarTramite}>Buscar</Button>
            </div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 place-items-stretch gap-4">
          {consulta && consulta.emisiones && consulta.emisiones.length > 0 && (
            <DataTable
              data={consulta.emisiones}
              columns={columns}
              action={""}
            />
          )}
          {consulta && consulta.emisiones.length === 0 && (
            <div className="my-4 rounded bg-primary-light py-2 text-center text-white">
              No se encontró Información
            </div>
          )}
          {loadingC && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
