import { useParams } from "react-router-dom";
import { useGetNotificacionesQuery } from "../services/docs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/Acordion";
import { Spinner } from "@phosphor-icons/react";
import { DataTable } from "./EjemploTabla";

const columns: any[] = [
  {
    accessorKey: "fi_etapa",
    header: "Etapa",
  },
  {
    accessorKey: "fi_movimiento",
    header: "Movimiento",
  },
  {
    accessorKey: "fc_notificacion",
    header: "Notificación",
  },
  {
    accessorKey: "fc_mensaje_notificacion",
    header: "Mensaje",
  },
  {
    accessorKey: "fd_fecha_envio",
    header: "Fecha Envio",
  },
];
export const AcordionNotificacionesMailComponent = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams();
  const { data, isLoading } = useGetNotificacionesQuery({
    siniestro,
    tramite: tramite_id,
  });
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      defaultValue="activos"
    >
      <AccordionItem value="activos">
        <div className="card">
          <AccordionTrigger>
            <p className="text-1xl">Notificaciones Correo</p>
          </AccordionTrigger>
          <AccordionContent>
            <div className="space-y-4">
              <div className="group space-y-4">
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  <li className="py-3 sm:py-4">
                    <div className="flex items-start space-x-10">
                      {data.notificacionesAppMail.length > 0 &&
                        data.notificacionesAppMail.map((e) => {
                          let fecha = new Date(e.fd_fecha_envio);
                          let formatoFecha = `${fecha.getDate()}/${
                            fecha.getMonth() + 1
                          }/${fecha.getFullYear()} ${fecha.getHours()}:${fecha.getMinutes()}:${fecha.getSeconds()}`;
                          return (
                            <div className="flex-2" key={e.id}>
                              <p className="text-lg text-primary">
                                {e.fc_notificacion}
                              </p>
                              <p className="text-muted text-sm">
                                {e.fc_correo}
                              </p>
                              <p className="text-muted text-sm">
                                {formatoFecha}
                              </p>
                              <p className="text-muted text-sm">
                                {e.fi_estatus_envio === 1
                                  ? "Mensaje enviadó"
                                  : "Mensaje no enviado"}
                              </p>
                              <p className="text-muted text-primary">
                                <a href={e.fc_mail} target="_blank">
                                  ver correo
                                </a>
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionContent>
        </div>
      </AccordionItem>
    </Accordion>
  );
};
