import * as XLSX from "xlsx";
import Button from "./ui/Button";

export const ReportesComponent = (prop: { data: any }) => {
  const postProperties = Object.keys(prop.data[0]);
  const descargarExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(prop.data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `reporte_${new Date().getDate()}.xlsx`);
  };
  return (
    <div className="block w-full overflow-x-auto">
      <Button onClick={descargarExcel} className="my-4">
        Descargar Excel
      </Button>
      <table className="mb-4 w-full border-collapse items-center bg-transparent ">
        <thead>
          <tr>
            {postProperties.map((property, index) => (
              <td
                className="whitespace-nowrap border-l-0 border-r-0 border-t-0 bg-primary p-4 px-6 text-left align-middle text-xs text-white "
                key={index}
              >
                {property}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {prop.data.map((valor, index) => (
            <tr>
              {postProperties.map((property, index) => (
                <td
                  className="whitespace-nowrap border-l-0 border-r-0 border-t-0 p-4 px-6 text-left align-middle text-xs text-gray-800 "
                  key={valor[property] ? valor[property] + index : index}
                >
                  {valor[property]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
