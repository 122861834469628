"use-client";
import { AnimatePresence, motion } from "framer-motion";
import { ButtonHTMLAttributes } from "react";
import { IIconProps, Icon } from "./Icon";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import Loader from "./Loader";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "secondary" | "dark" | "light" | "danger";
  tipo?: "relleno" | "contorneado" | "solo-paddings";
  size?: "sm" | "base" | "lg" | "xl";
  loading?: boolean;
  icono?: IIconProps;
}

const baseClasses =
  "relative flex items-center justify-center gap-2 focus:outline-none rounded overflow-hidden";

const buttonVariants = cva(baseClasses, {
  variants: {
    color: {
      primary: "text-primary bg-primary focus-visible:ring-primary-ultra-light",
      secondary:
        "text-primary bg-primary-ultra-light focus-visible:ring-primary-light",
      dark: "text-dark bg-dark focus-visible:ring-dark/20",
      light: "!text-dark bg-light focus-visible:ring-light/40",
      danger: "text-danger bg-danger focus-visible:ring-danger/20",
    },
    tipo: {
      relleno: "!text-light border-0",
      contorneado: "border-2 !bg-transparent",
      "solo-paddings": "border-0 !bg-transparent",
    },
    size: {
      sm: "px-3 py-1 text-xs",
      base: "px-4 py-2 text-sm",
      lg: "px-5 py-3 text-base",
      xl: "px-6 py-4 text-lg",
    },
  },
  defaultVariants: {
    color: "primary",
    tipo: "relleno",
    size: "base",
  },
});

export const Button = ({
  color = "primary",
  tipo = "relleno",
  size = "base",
  loading = false,
  icono,
  children,
  type = "button",
  ...props
}: IButtonProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick && !loading) {
      props.onClick(event);
    } else if (loading) {
      event.preventDefault();
    }
  };

  return (
    <motion.button
      {...(props as any)}
      type={type}
      disabled={loading}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      whileHover={{
        y: -2,
      }}
      whileTap={{
        scale: 0.95,
      }}
      transition={{ duration: 0.2 }}
      className={cn(
        buttonVariants({ color, tipo, size }),
        (loading || props.disabled) && "pointer-events-none opacity-80",
        props?.className
      )}
    >
      <div className="pointer-events-none h-full w-full">
        {loading && (
          <div className="pointer-events-none flex items-center justify-center gap-2">
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            {icono ? (
              <div className="flex items-center justify-center gap-2">
                <Icon variante={icono.variante} />
                <span className="block truncate">{children}</span>
              </div>
            ) : (
              <span className="block truncate">{children}</span>
            )}
          </>
        )}
      </div>

      <AnimatePresence mode="wait">
        {isHovered && (
          <motion.div
            key="hover"
            initial={{ opacity: 0, scaleX: 0, borderRadius: "50%" }}
            animate={{ opacity: 0.1, scaleX: "100%", borderRadius: "0%" }}
            exit={{ opacity: 0, scaleX: 0, borderRadius: "50%" }}
            transition={{ duration: 0.25 }}
            className="absolute inset-0 z-[-1] h-full w-full bg-white opacity-0"
          />
        )}
      </AnimatePresence>
    </motion.button>
  );
};

export default Button;
