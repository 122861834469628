import { useForm } from "../../../hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEnviarAseguradoraMutation } from "../../../services/docs";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const EnviarAseguradoraScreen = () => {
  const { siniestro, tramite_id = 0 } = useParams();
  const [enviarAseg] = useEnviarAseguradoraMutation();
  const navigate = useNavigate();
  const { formulario, handleInputChange } = useForm({
    fc_siniestro_aseguradora: "",
    tramite_id: Number(tramite_id),
    siniestro: siniestro,
  });
  const handleAction = async () => {
    console.log(formulario);
    const resp = await enviarAseg({
      ...formulario,
      tramite_id: +tramite_id,
    }).unwrap();
    toastr.info(resp.message);
    navigate("/view/siniestro/" + siniestro + "/" + tramite_id + "/");
  };
  return (
    <div className="flex w-full">
      <div className="form">
        <div className="my-5 flex flex-col">
          <Label htmlFor="">Numero Aseguradora:</Label>
          <Input
            type="text"
            name="fc_siniestro_aseguradora"
            id="fc_siniestro_aseguradora"
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex-col ">
          <Button onClick={handleAction}>Enviar a aseguradora</Button>
        </div>
      </div>
    </div>
  );
};
