import { useParams, Link } from "react-router-dom";
import { useGetSiniestroQuery } from "../../services/docs";
import { ItemComponent } from "../../components/ItemComponent";
import { Spinner } from "../../components/Spinner";

export const SiniestroScreen = () => {
  let { siniestro='x' } = useParams()
  const { data, error, isLoading } = useGetSiniestroQuery(siniestro)
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  if (error) {
    let err:any = error;
    return (<><h1>Siniestro no encontrado {err.error}</h1></>)
  }
  if (!isLoading) {
    if (data.siniestro) {
      const s = data.siniestro;
      return (
        <>
          <div className="bg-white pt-12 pr-0 pb-12 pl-0 mt-0 mr-auto mb-0 ml-auto sm:py-16 lg:py-20">
            {data && s &&
              (
                <>
                  <div className="bg-white pt-12 pr-0 pb-12 pl-0 mt-0 mr-auto mb-0 ml-auto sm:py-16 lg:py-20">
                    <ItemComponent titulo={'Siniestro: '} subtitulo={s.s_fc_siniestro} />
                    <ItemComponent titulo={'Producto: '} subtitulo={s.s_fc_descripcion_producto} />
                    <ItemComponent titulo={'Aseguradora: '} subtitulo={s.s_fc_descripcion_aseguradora} />
                    <ItemComponent titulo={'Cobertura: '} subtitulo={s.s_fc_descripcion_cobertura} />
                    <ItemComponent titulo={'Fecha Siniestro: '} subtitulo={s.s_fd_fecha_siniestro} />
                    <h1 className="ml-4 text-2xl">Tramites</h1>
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right">Tramite</th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right">Clave Asegurado</th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right">Número Asegurado</th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right">Monto Reclamado</th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right">Etapa</th>
                          <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y">
                        {data.tramites.map((t: any) => {
                          return (
                            <tr key={t.id}>
                              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">{t.id}</td>
                              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">{t.fc_clave_asegurado}</td>
                              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">{t.fi_numero_asegurado}</td>
                              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">{t.fn_monto_reclamado}</td>
                              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">{t.fi_etapa}</td>
                              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap"><a href={`/view/siniestro/${t.fc_siniestro}/${t.id}/proceso`} >Ver mas</a></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )
            }
          </div>
        </>
      )
    }
  }
  return <></>
}
