import { AcordionNotificacionesComponent } from "../../../components/AcordionNotificacionesComponent";
import { AcordionNotificacionesMailComponent } from "../../../components/AcordionNotificacionesMailComponent";
export const ContactoClienteScreen = () => {
  return (
    <>
      <AcordionNotificacionesComponent />
      <AcordionNotificacionesMailComponent />
    </>
  );
};
