import { Route, Routes } from "react-router-dom";

import { BuscarScreen } from "../pages/private/200/BuscarScreen";
import { HomeScreen } from "../pages/private/200/HomeScreen";
import { MisPendientesScreen } from "../pages/private/200/MisPendientes";
import { NuevoTramite } from "../pages/private/200/NuevoTramiteScreen";
import { NuevoTramitePoliza } from "../pages/private/200/NuevoTramitePolizaScreen";
import { ValidacionesScreen } from "../pages/private/200/ValidacionesScreen";
import { AsignarTramitesScreen } from "../pages/private/200/AsignarTramitesScreen";
import { AgregarEspecialistaScreen } from "../pages/private/200/AgregarEspecialistasScreen";
import { CondicionesScreen } from "../pages/private/200/CondicionesScreen";
import { ReportesScreen } from "../pages/private/200/ReportesScreen";
import { FormatosScreen } from "../pages/private/200/FormatosScreen";
import { WakawayScreen } from "../pages/private/200/WakawayScreen";

export const PrivadaRouter = () => {
  return (
    <Routes>
      <Route path="/inicio" element={<HomeScreen />}></Route>
      <Route path="/buscar" element={<BuscarScreen />}></Route>
      <Route path="/validaciones" element={<ValidacionesScreen />}></Route>
      <Route path="/mis-pendientes" element={<MisPendientesScreen />}></Route>
      <Route path="/nuevo-tramite" element={<NuevoTramite />}></Route>
      <Route path="/nuevo-tramite/:id" element={<NuevoTramitePoliza />}></Route>
      <Route path="/condiciones" element={<CondicionesScreen />}></Route>
      <Route path="/formatos" element={<FormatosScreen />}></Route>
      <Route path="/reportes" element={<ReportesScreen />}></Route>
      <Route path="/*" element={<HomeScreen />}></Route>
      <Route
        path="/asignar-tramites"
        element={<AsignarTramitesScreen />}
      ></Route>
      <Route
        path="/agregar-especialista"
        element={<AgregarEspecialistaScreen />}
      ></Route>
      <Route path="/wakaway" element={<WakawayScreen />}></Route>
    </Routes>
  );
};
