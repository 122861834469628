import React from 'react'
import { IndexRoutes } from './routes/IndexRoutes'
import { useValidarSesionQuery } from './services/docs'
import { Spinner } from './components/Spinner'

const App: React.FC = () => {
  const { data, isLoading, error } = useValidarSesionQuery()
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  if (error) {
    let err: any = error
    if (err.status === 403) {
      localStorage.setItem('app_token', '')
    }
  }
  if (data) {
    if (data.statusCode) {
      if (data.statusCode === 403) {
        localStorage.setItem('app_token', '')
      }
    }

  }

  return <IndexRoutes />
}

export default App
