import { Route, Routes } from 'react-router-dom'
import { LoginScreen } from '../pages/public/loginScreen'


export const LibreRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />}></Route>
      <Route path="/expire" element={<LoginScreen />}></Route>
      <Route path="/actualiz" element={<LoginScreen />}></Route>
      <Route path="/*" element={<LoginScreen />}></Route>
    </Routes>
  )
}
