import { Routes, Route } from "react-router-dom"
import { PublicLayout } from '../layout/publicLayout'
import { LoginAuth } from "../hooks/LoginAuth"
import { PrivateLayout } from "../layout/privateLayout"
import { RequiereAuth } from "../hooks/RequiereAuth"
import { PrivadaRouter } from "./PrivateRoutes"
import { ViewRouter } from "./ViewRoutes"
import { LibreRouter } from "./LibreRouter"
import { useSelector } from "react-redux"
import { RootState } from "../store"
import { EmsionesRoutes } from "./EmisionesRoutes"
import { AuthLayout } from "../layout/authLayout"
import { LoginScreen } from "../pages/public/loginScreen"

export const IndexRoutes = () => {
  return (
    <Routes >
      <Route element={<AuthLayout />}>
        <Route element={<PrivateLayout />}>
          <Route
            path="/home/*"
            element={
              <RequiereAuth perfil=''>
                <PrivadaRouter />
              </RequiereAuth>
            }
          ></Route>
          <Route
            path="/emisiones/*"
            element={
              <RequiereAuth perfil='emisiones'>
                <EmsionesRoutes />
              </RequiereAuth>
            }
          ></Route>
          <Route
            path="/view/*"
            element={
              <RequiereAuth perfil=''>
                <ViewRouter />
              </RequiereAuth>
            }
          ></Route>
        </Route>
      </Route>
      <Route element={<PublicLayout />}>
        <Route
          path="/auth/*"
          element={
            <LibreRouter />
          }
        ></Route>
      </Route>
      <Route path="/*" element={<LoginScreen />}></Route>
    </Routes>
  )
}
