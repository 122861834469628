import { Spinner } from "../../../components/Spinner";
import {
  useBuscarTramiteMutation,
  useGetBusquedaQuery,
} from "../../../services/docs";
import { useForm } from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { TablaTramitesComponent } from "../../../components/TablaTramitesComponent";

export const BuscarScreen = () => {
  const { data, isLoading } = useGetBusquedaQuery();
  const navigate = useNavigate();
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fi_busqueda_id: "",
    fc_valor: "",
  });
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`);
  };
  const [buscarTramiteAction, { data: siniestros, error }] =
    useBuscarTramiteMutation();
  const buscarTramite = async () => {
    if (+formulario.fi_busqueda_id === 0) {
      toastr.error("Debe seleccionar un tipo de búsqueda");
      return;
    }
    if (formulario.fc_valor === "") {
      toastr.error("Debes de agregar un valor de busqueda");
      return;
    }
    await buscarTramiteAction({
      ...formulario,
      fi_busqueda_id: +formulario.fi_busqueda_id,
    }).unwrap();
  };
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="card">
        <div className="w-full">
          <h1 className="text-2xl">Buscar Siniestro</h1>
          <hr className="my-2" />
        </div>
        <div className="grid grid-cols-3 place-items-stretch gap-4">
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label htmlFor="fi_busqueda_id" className="my-2">
                Tipo Búsqueda:
              </Label>
              <select
                className="focus-visible:ring-ring flex h-12 w-full items-center justify-between rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50"
                name="fi_busqueda_id"
                onChange={handleSelectChange}
              >
                <option value="">Selecciona</option>
                {data &&
                  data.busqueda &&
                  data.busqueda.map((b: any) => {
                    return (
                      <option key={b.id} value={b.id}>
                        {b.fc_nombre_busqueda}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div>
            <div className="flex flex-col px-6 py-2">
              <Label className="my-2" htmlFor="fc_valor">
                Valor a buscar:
              </Label>
              <Input type="text" name="fc_valor" onChange={handleInputChange} />
            </div>
          </div>
          <div>
            <div className="mt-6 flex flex-col px-6 py-2">
              <Button onClick={buscarTramite}>Buscar</Button>
            </div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 place-items-stretch gap-4">
          {siniestros &&
            siniestros.busquedas &&
            siniestros.busquedas.length > 0 && (
              <TablaTramitesComponent
                data={siniestros.busquedas}
                texto_accion="Ver mas"
                accion={verMas}
                ejecutivos={[]}
                cargando={false}
              />
            )}
          {siniestros &&
            siniestros.busquedas &&
            siniestros.busquedas.length === 0 && (
              <div className="my-4 rounded bg-danger py-2 text-center text-white">
                No se encontraron datos
              </div>
            )}
        </div>
      </div>
    </>
  );
};
