import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { RootState } from '../store'
import { useEffect, useState } from 'react'
import { useLazyValidarSesionQuery } from '../services/docs'
import { cambiaValida, iniciarSesion } from '../features/ui/uiSlice'
import { Spinner } from '@phosphor-icons/react'

export const AuthLayout = () => {
  const { token, usuario,valida } = useSelector((state: RootState) => state.ui)
  const [validaAction] = useLazyValidarSesionQuery();
  const [isLoading,setloading] = useState(false)
  const dispatch = useDispatch()
  if (!token) {
    return <Navigate to="/auth/login" replace />
  }
  const validaSesion = async ()=>{
      const data =  await validaAction().unwrap()
      dispatch(iniciarSesion(data.user))
      setloading(false)
  }
  useEffect(()=>{
    if (!usuario.id) {
      dispatch(cambiaValida())
      setloading(true)
      validaSesion()
    }
  },[usuario])

  if(isLoading || valida){
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return <Outlet />
}
