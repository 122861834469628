import { Route, Routes } from "react-router-dom";

import { CargaDocumentosScreen } from "../pages/view/proceso/cargaDocumentosScreen";
import { ProcesoScreen } from "../pages/view/proceso/ProcesoScreen";
import { ContactoClienteScreen } from "../pages/view/tabs/ContactoClienteScreen";
import { DictamenesScreen } from "../pages/view/tabs/DictamenesScreen";
import { ExpendienteScreen } from "../pages/view/tabs/ExpedienteScreen";
import { TiemposScreen } from "../pages/view/tabs/TiemposScreen";
import { PrevalidacionScreen } from "../pages/view/proceso/PrevalidacionScreen";
import { EnviarAseguradoraScreen } from "../pages/view/proceso/EnviarAseguradoraScreen";
import { AseguradoraScreen } from "../pages/view/proceso/AseguradoraScreen";
import { AuditoriaScreen } from "../pages/view/proceso/AuditoriaScreen";
import { CallcenterScreen } from "../pages/view/proceso/CallcenterScreen";
import { FinalizadoScreen } from "../pages/view/proceso/FinalizadoScreen";

export const TramitesRouter = () => {
  return (
    <Routes>
      <Route path="/proceso" element={<ProcesoScreen />}></Route>
      <Route
        path="/contacto-cliente"
        element={<ContactoClienteScreen />}
      ></Route>
      <Route path="/expediente" element={<ExpendienteScreen />}></Route>
      <Route path="/tiempos" element={<TiemposScreen />}></Route>
      <Route path="/dictamenes" element={<DictamenesScreen />}></Route>
      <Route
        path="/carga-documentacion"
        element={<CargaDocumentosScreen />}
      ></Route>
      <Route path="/prevalidacion" element={<PrevalidacionScreen />}></Route>
      <Route
        path="/envio-aseguradora"
        element={<EnviarAseguradoraScreen />}
      ></Route>
      <Route path="/aseguradora" element={<AseguradoraScreen />}></Route>
      <Route path="/auditoria" element={<AuditoriaScreen />}></Route>
      <Route path="/callcenter" element={<CallcenterScreen />}></Route>
      <Route path="/fin" element={<FinalizadoScreen />}></Route>
      <Route path="/*" element={<ProcesoScreen />}></Route>
    </Routes>
  );
};
