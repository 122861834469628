import { ChangeEvent, useState } from 'react'

export const useForm = <T extends Object> (initialState: T ) => {
  const [formulario, setFormulario] = useState(initialState)

  const reset = (inicial) => {
    setFormulario(inicial)
  }

  const handleInputChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    const { name, value} = target
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleSelectChange = ({target}: ChangeEvent<HTMLSelectElement>) => {
    const { name, value} = target
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleTexareChange = ({target}: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value} = target
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleChange = (name:string,value:string) => {
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleChangeAll = (data) => {
    setFormulario({
      ...formulario,
      ...data
    })
  }
  return {reset, formulario,handleChangeAll, handleInputChange,handleSelectChange,handleTexareChange,handleChange}
}
