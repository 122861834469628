export const elapsed = (fecha: string) => {
  let seconds = 0;
  let counter = 0;
  let tiempo = "";
  const intervals = {
    año: 31536000,
    mes: 2592000,
    semana: 604800,
    dia: 86400,
    hora: 3600,
    minuto: 60,
    segundo: 1,
  };
  seconds = Math.floor((+new Date() - +new Date(fecha)) / 1000);
  if (seconds < 0) {
    return "Ahora";
  }
  for (const i in intervals) {
    counter = Math.floor(seconds / intervals[i]);
    if (counter > 0) {
      seconds = seconds - intervals[i] * counter;
      if (counter === 1) {
        tiempo += counter + " " + i + " ";
      } else {
        tiempo += counter + " " + i + "s ";
      }
    }
  }
  return tiempo;
};
export const elapsedCerrado = (fecha: string, fechaDos: string) => {
  let seconds = 0;
  let counter = 0;
  let tiempo = "";
  const intervals = {
    año: 31536000,
    mes: 2592000,
    semana: 604800,
    dia: 86400,
    hora: 3600,
    minuto: 60,
    segundo: 1,
  };
  seconds = Math.floor((+new Date(fechaDos) - +new Date(fecha)) / 1000);
  if (seconds < 0) {
    return "Ahora";
  }
  for (const i in intervals) {
    counter = Math.floor(seconds / intervals[i]);
    if (counter > 0) {
      seconds = seconds - intervals[i] * counter;
      if (counter === 1) {
        tiempo += counter + " " + i + " ";
      } else {
        tiempo += counter + " " + i + "s ";
      }
    }
  }
  return tiempo;
};
export const elapsedMinutos = (minutos: number) => {
  let seconds = 0;
  let counter = 0;
  let tiempo = "";
  const intervals = {
    año: 31536000,
    mes: 2592000,
    semana: 604800,
    dia: 86400,
    hora: 3600,
    minuto: 60,
    segundo: 1,
  };
  seconds = Math.floor(minutos * 60);
  if (seconds < 0) {
    return "Ahora";
  }
  for (const i in intervals) {
    counter = Math.floor(seconds / intervals[i]);
    if (counter > 0) {
      seconds = seconds - intervals[i] * counter;
      if (counter === 1) {
        tiempo += counter + " " + i + " ";
      } else {
        tiempo += counter + " " + i + "s ";
      }
    }
  }
  return tiempo;
};
export const agregar_ceros = (numero: number) => {
  return numero < 10 ? "0" + numero : numero;
};
export const fecha_actual = (fecha_string: string) => {
  let fecha = new Date(fecha_string);
  return `${agregar_ceros(fecha.getDate())}/${agregar_ceros(
    fecha.getMonth() + 1
  )}/${fecha.getFullYear()} ${agregar_ceros(fecha.getHours())}:${agregar_ceros(
    fecha.getMinutes()
  )}:${agregar_ceros(fecha.getSeconds())}`;
};
